import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React from 'react';
import { Link } from "react-router-dom";

import './../App.css';
import './SectionHobby.css';
import { mainPage } from '../constants/pageName';

import codingIcon from '../assets/images/hobby_icon/coding-icon.png';
import gamingIcon from '../assets/images/hobby_icon/gaming-icon.png';
import movieIcon from '../assets/images/hobby_icon/movie-icon.png';
import musicIcon from '../assets/images/hobby_icon/music-icon.png';
import sportIcon from '../assets/images/hobby_icon/sport-icon.png';
import writingIcon from '../assets/images/hobby_icon/writing-icon.png';

const SectionHobby = () => {
	return (
    <div className='pageSection' id='sectionHobby'>
      <div className='sectionHobbysub'>
        <h2 id='hHobby'>Hobby</h2>
        <p id='pHobby'>
          I also love:
        </p>

        <div className='hobbyAll'>
          <div className='hobbyGamingIconWrapper'>
            <img src={gamingIcon} className='hobbyIcon' alt='gaming-icon.png'/>
            <a href='###'>
              <div className='overlayleftslide'>
                <div className='hobbyText'>
                  <p>RPG, FPS</p>
                  <p>Adventure</p>
                  <p>Survival horror</p>
                </div>
              </div>
            </a>
          </div>

          <div className='hobbySportIconWrapper'>
            <img src={sportIcon} className='hobbyIcon' alt='sport-icon.png'/>
            <a href='###'>
              <div className='overlaytopslide'>
                <div className='hobbyText'>
                  <p>Basketball, Jogging</p>
                  <p>Swimming, gym</p>
                </div>
              </div>
            </a>
          </div>

          <div className='hobbyMovieIconWrapper'>
            <img src={movieIcon} className='hobbyIcon' alt='movie-icon.png'/>
            <a href='###'>
              <div className='overlayrightslide'>
                <div className='hobbyText'>
                  <p>Action, Thriller</p>
                  <p>Sci-Fi</p>
                </div>
              </div>
            </a>
          </div>

          <div className='hobbyMusicIconWrapper'>
            <img src={musicIcon} className='hobbyIcon' alt='music-icon.png'/>
            <Link to={mainPage.myPiano}>
              <div className='overlaybottomslide'>
                <div className='hobbyText'>
                  <p>Pop, Rock, Blue</p>
                  <p>Country, Rap</p>
                  <p>Piano</p>
                </div>
              </div>
            </Link>
          </div>

          <div className='hobbyWrittingIconWrapper'>
            <img src={writingIcon} className='hobbyIcon' alt='writing-icon.png'/>
            <Link to={mainPage.myArticle}>
              <div className='overlayzoom'>
                <div className='hobbyText'>
                  <p>Description, Review</p>
                  <p>Perspective</p>
                  <p>Short Story</p>
                </div>
              </div>
            </Link>
          </div>
          
          <div className='hobbyCodingIconWrapper'>
            <img src={codingIcon} className='hobbyIcon' alt='coding-icon.png'/>
            <a href='###'>
              <div className='overlayfade'>
                <div className='hobbyText'>
                  <p>Web Development</p>
                  <p>Game Development</p>
                </div>
              </div>
            </a>
          </div>
          
        </div>
      </div>
    </div>
	)
}

export default SectionHobby;