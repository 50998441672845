import React, { MouseEventHandler } from 'react'

import './../App.css';
import './MainBtn.css';

type mainBtnProps = {
  onClick: MouseEventHandler,
  text: string
}

const MainBtn = ({ onClick, text }: mainBtnProps) => {
  return (
    <button className='btn btn-outline-secondary btn-lg' id='hrefBtn' onClick={onClick}>
      {text}
    </button>
  )
}

export default MainBtn;