import Collapsible from 'react-collapsible';

import './CollapsibleView.css';

const collapsibleViewStyle: React.CSSProperties = {
  border: '1px solid black',
  borderRadius: '10px',
  display: 'block',
  padding: '10px',
  marginTop: '10px',
  marginBottom: '10px'
}

const collapsibleViewStyleNested: React.CSSProperties = {
  border: '1px solid black',
  borderRadius: '10px',
  display: 'block',
  padding: '10px',
  marginTop: '10px',
  marginBottom: '10px',
  marginLeft: '50px',
}
const collapsibleViewStyleNestedText: React.CSSProperties = {
  marginLeft: '60px',
}

export const CollapsibleViewGame  = () => {
  return (
    <Collapsible
      className='collapsibleViewStyleHover'
      transitionTime={400}
      trigger='mainFolder'
      easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
      triggerStyle={collapsibleViewStyle}
    >
      <ul>
        <li>_Prefab</li>
        <li>Animation</li>
        <li>Resources</li>
        <li>Scenes</li>
        <li>Scripts</li>
        <li>2D Sprites</li>
        <li>Effects</li>
        <li>External Assets</li>
      </ul>
    </Collapsible>
  );
};

export const CollapsibleViewWeb = () => {
  return (
    <Collapsible
      className='collapsibleViewStyleHover'
      transitionTime={400}
      trigger='mainFolder'
      easing={'cubic-bezier(0.175, 0.885, 0.32, 2.275)'}
      triggerStyle={collapsibleViewStyle}
    >
      <Collapsible
        className='collapsibleViewStyleHover'
        transitionTime={200}
        trigger='css'
        triggerStyle={collapsibleViewStyleNested}
      >
        <p style={collapsibleViewStyleNestedText}>&#8594; mainstyle.css</p>
      </Collapsible>
      <Collapsible
        className='collapsibleViewStyleHover'
        transitionTime={200}
        trigger='js'
        triggerStyle={collapsibleViewStyleNested}
      >
        <p style={collapsibleViewStyleNestedText}>&#8594; script.js</p>
      </Collapsible>
      <Collapsible
        className='collapsibleViewStyleHover'
        transitionTime={200}
        trigger='php'
        triggerStyle={collapsibleViewStyleNested}
      >
        <p style={collapsibleViewStyleNestedText}>&#8594; function.php</p>
      </Collapsible>
      <Collapsible
        className='collapsibleViewStyleHover'
        transitionTime={200}
        trigger='images'
        triggerStyle={collapsibleViewStyleNested}
      >
        <p style={collapsibleViewStyleNestedText}>&#8594; img01.php</p>
        <p style={collapsibleViewStyleNestedText}>&#8594; img02.php</p>
        <p style={collapsibleViewStyleNestedText}>&#8594; img03.php</p>
      </Collapsible>
      <p>&#8594; index.html</p>
      <p>&#8594; pageA.html</p>
      <p>&#8594; pageB.html</p>
      <p>&#8594; pageC.html</p>
    </Collapsible>
  )
}