import React, { FC, useMemo } from 'react';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';

type Person = {
  dateTime: string,
  sunday: string,
  monday: string,
  tuesday: string,
  wednesday: string,
  thursday: string,
  friday: string,
  saturday: string
};

//nested data is ok, see accessorKeys in ColumnDef below
const data: Person[] = [
  {
    dateTime: '08:30 - 09:30',
    sunday: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: ''
  },
  {
    dateTime: '09:30 - 10:30',
    sunday: '',
    monday: 'In Office',
    tuesday: 'In Office',
    wednesday: 'In Office',
    thursday: 'In Office',
    friday: 'In Office',
    saturday: ''
  },
  {
    dateTime: '10:30 - 11:30',
    sunday: '',
    monday: 'In Office',
    tuesday: 'In Office',
    wednesday: 'In Office',
    thursday: 'In Office',
    friday: 'In Office',
    saturday: ''
  },
  {
    dateTime: '11:30 - 12:30',
    sunday: '',
    monday: 'In Office',
    tuesday: 'In Office',
    wednesday: 'In Office',
    thursday: 'In Office',
    friday: 'In Office',
    saturday: ''
  },
  {
    dateTime: '12:30 - 13:30',
    sunday: '',
    monday: 'Lunch',
    tuesday: 'Lunch',
    wednesday: 'Lunch',
    thursday: 'Lunch',
    friday: 'Lunch',
    saturday: ''
  },
  {
    dateTime: '13:30 - 14:30',
    sunday: '',
    monday: 'In Office',
    tuesday: 'In Office',
    wednesday: 'In Office',
    thursday: 'In Office',
    friday: 'In Office',
    saturday: ''
  }, 
  {
    dateTime: '14:30 - 15:30',
    sunday: '',
    monday: 'In Office',
    tuesday: 'In Office',
    wednesday: 'In Office',
    thursday: 'In Office',
    friday: 'In Office',
    saturday: ''
  }, 
  {
    dateTime: '15:30 - 16:30',
    sunday: '',
    monday: 'In Office',
    tuesday: 'In Office',
    wednesday: 'In Office',
    thursday: 'In Office',
    friday: 'In Office',
    saturday: ''
  }, 
  {
    dateTime: '16:30 - 17:30',
    sunday: '',
    monday: 'In Office',
    tuesday: 'In Office',
    wednesday: 'In Office',
    thursday: 'In Office',
    friday: 'In Office',
    saturday: ''
  }, 
  {
    dateTime: '17:30 - 18:30',
    sunday: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: ''
  }, 
  {
    dateTime: '18:30 - 19:30',
    sunday: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: ''
  }, 
  {
    dateTime: '19:30 - 20:30',
    sunday: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: ''
  }, 
  {
    dateTime: '20:30 - 21:30',
    sunday: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: ''
  }
];

const MaterialTable: FC = () => {
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<Person>[]>(
    () => [
      {
        accessorKey: 'dateTime', //access nested data with dot notation
        header: 'Day / Time',
      },
      {
        accessorKey: 'sunday',
        header: 'Sunday',
      },
      {
        accessorKey: 'monday', //normal accessorKey
        header: 'Monday',
      },
      {
        accessorKey: 'tuesday',
        header: 'Tuesday',
      },
      {
        accessorKey: 'wednesday',
        header: 'Wednesday',
      },
      {
        accessorKey: 'thursday',
        header: 'Thursday',
      },
      {
        accessorKey: 'friday',
        header: 'Friday',
      },
      {
        accessorKey: 'saturday',
        header: 'Saturday',
      },
    ],
    [],
  );

  return <MaterialReactTable columns={columns} data={data} defaultColumn={{size: 160}} />;
};

export default MaterialTable;
