import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';

import './../../App.css';
import './../IdeaPage.css';
import './studyPage.css';
import './../../components/CodeBlock.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';

import ec2Img1 from '../../assets/images/study/EC2_1.png';
import ec2Img2 from '../../assets/images/study/EC2_2.png';
import ec2Img3 from '../../assets/images/study/EC2_3.png';
import ec2Img4 from '../../assets/images/study/EC2_4.png';

const EC2Body = () => {
  useEffect(() => window.scrollTo(0,0), []);

	return (
    <div className='step-container'>
      <h1>Host Your Dynamic Website Using AWS EC2</h1>
      <hr/>
      <div className='step-container-body'>
        <div id='study-contents'>
          <h2>Edit: 03-01-2023</h2>
          Please noted the date here since different versions may not be operated in same way.

          <h2>Pre-Requirements</h2>
          <ul>
            <li>Amazon Web Services Account</li>
          </ul>

          <h2>Modules Will Be Used</h2>
          <ul>
            <li>EC2</li>
            <li>CloudFont (Optional: for domain name)</li>
            <li>Route 53 (Optional: for domain name)</li>
            <li>Certificate Manager (Optional: for domain name)</li>
          </ul>

          <h2>Step 1: Create new EC2 instance</h2>
          <div>
            First, click on the Launch instances button at top right hand corner to create new EC2 instance.
            <ol>
              <li>Set instance name, i.e. myWebServer</li>
              <li>Chose your AWS machine image, i.e. Amazon Linux</li>
            </ol>
            <img src={ec2Img1} className='imageBox' alt=''/>
            <p style={{ fontStyle: 'italic', color: 'red' }}>
              # Amazon Linux 2 command is a bit different from Linux command, 
              for example it uses <b>sudo yum install</b> instead of <b>sudo apt install</b>.
              If you feel uncomfortable for that you can use Ubuntu image here.
            </p>
          </div>

          <h2>Step 2: Create Key pair</h2>
          <div>
            Create ssh key for using other applications such as <b>PUTTY</b> or <b>FileZilla</b> to connect to your EC2 instance.
            <img src={ec2Img2} className='imageBox' alt=''/>
            After creating the key you can select your ssh key for this EC2 instance.
            <p style={{ fontStyle: 'italic', color: 'red' }}>
              # You can only download your key once, put your key into a safe place on disk or other trusted cloud drive.
              If you lose your key, you may need to create a new instance and re-deploy your website.
              Now AWS provide connect via web, but it is still necessary to keep your ssh key.
            </p>
          </div>

          <h2>Step 3: Launch Instance</h2>
          <div>
            The basic config will be good enough for most of web applications. Now click <b>Launch instance</b> button on left.
            <img src={ec2Img3} className='imageBox' alt=''/>
            To allow public access to your EC2 instance:
            <ol>
              <li>Select instance</li>
              <li>Click on security tag</li>
              <li>Click on Security groups</li>
              <li>Click on Edit inbound rules button</li>
              <li>Add All traffic with IPv4 and IPv6</li>
            </ol>
            After doing the above steps you will get this:
            <img src={ec2Img4} className='imageBox' alt=''/>
          </div>

          <h2>Step 4: Project Deployment</h2>
          <div>
            Use <b>PHP</b>, <b>mySQL</b> web project as example:
            <ol>
              <li>Connect to EC2 instance via web or PUTTY</li>
              <li>
                Set up environment: <a href='https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-lamp-amazon-linux-2.html' target='_blank' rel='noreferrer'>Install a LAMP web server on Amazon Linux 2</a>.
                If you are too lazy to read just enter these commands:
                <br/>
                <div id='cmdline'>
                  For PHP:
                  <ol>
                    <li>sudo yum update -y</li>
                    <li>sudo yum install -y httpd mariadb-server</li>
                    <li>sudo systemctl enable httpd</li>
                    <li>sudo usermod -a -G apache ec2-user</li>
                    <li>sudo chown -R ec2-user:apache /var/www</li>
                    <li>sudo chmod 2775 /var/www && find /var/www -type d -exec sudo chmod 2775 {} \;</li>
                    <li>find /var/www -type f -exec sudo chmod 0664 {} \;</li>
                    <li>Test: http://my.public.dns.amazonaws.com/phpinfo.php</li>
                    <li>rm /var/www/html/phpinfo.php</li>
                  </ol> 
                  For database server:
                  <ol>
                    <li>sudo systemctl start mariadb</li>
                    <li>Set password: sudo mysql_secure_installation</li>
                    <li>sudo systemctl enable mariadb</li>
                  </ol>
                  For phpMyAdmin:
                  <ol>
                    <li>sudo yum install php-mbstring php-xml -y</li>
                    <li>sudo systemctl restart php-fpm</li>
                    <li>cd /var/www/html</li>
                    <li>wget https://www.phpmyadmin.net/downloads/phpMyAdmin-latest-all-languages.tar.gz</li>
                    <li>mkdir phpMyAdmin && tar -xvzf phpMyAdmin-latest-all-languages.tar.gz -C phpMyAdmin --strip-components 1</li>
                    <li>rm phpMyAdmin-latest-all-languages.tar.gz</li>
                    <li>sudo systemctl start mariadb</li>
                    <li>Test: http://my.public.dns.amazonaws.com/phpMyAdmin</li>
                  </ol>
                </div>
              </li>
              <li>Put your php project into /var/www/html as usual</li>
              <li>Setup DB using phpMyAdmin as usual</li>
            </ol>
          </div>

          <h2>Step 5: Test</h2>
          <div>
            Congratulations, now you can host your dynamic website using EC2.
            To test your website enter: 
            <div id='cmdline'>
              http://my.public.dns.amazonaws.com/myPHPProjectFolderName
            </div>
            <p>
              Noted that different web application will have different ways to set up the environment.
              Please visit the official page of certain frameworks, 
              normally the deployment should be same or similar as other Linux servers.
            </p>
            <p style={{ fontStyle: 'italic', color: 'red' }}>
              # If you want to use the HTTPS, you will need to have a domain and SSL certificate.
              The <b>Route 53</b> section (Coming Soon) will show you how to register a domain in <b>Route 53</b> and get a SSL certificate using the <b>Certificate Manager</b>.
            </p>
          </div>
        </div>
      </div>  
    </div>
	)
}

const EC2BodyPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <div className='content-container'>
          <EC2Body />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default EC2BodyPage;