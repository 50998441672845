import React from 'react';

import './IndexPage.css';
import './../utils/scrollTriggerAnimation.js';
import HtmlFooter from './../components/HtmlFooter';
import Navbar from './../components/Navbar';
import Slideshow from './../components/Slideshow';
import SectionLine from './../components/SectionLine';
import BottomScrollProgressBarUI from './../components/BottomScrollProgressBar';
import CusScrollToTop from '../components/CusScrollToTop';
import { mainPage } from '../constants/pageName';

import SectionAboutMe from './../sections/SectionAboutMe';
import SectionSkills from './../sections/SectionSkills';
import SectionHobby from './../sections/SectionHobby';
import SectionSchedule from './../sections/SectionSchedule';
import SectionContact from './../sections/SectionContact';

import image1 from '../assets/images/slide/img01Text.png';
import image2 from '../assets/images/slide/img02Text.png';
import image3 from '../assets/images/slide/img03Text.png';
import image4 from '../assets/images/slide/img04Text.png';
import image5 from '../assets/images/slide/img05Text.png';
import image6 from '../assets/images/slide/img06Text.png';

const images = [
  image1, 
  image2, 
  image3, 
  image4,
  image5,
  image6,
];

const slideShowLink = [
  '/',
  mainPage.webDev,
  mainPage.gameDev,
  mainPage.myArticle,
  mainPage.myPiano,
  mainPage.gameUnity
];

const IndexPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={false}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        {/* Slideshow */}
        <Slideshow images={images} links={slideShowLink} />

        <div className='content-container'>
          <SectionAboutMe />
          <SectionLine />
        
          <SectionSkills/>
          <SectionLine />
        
          <SectionHobby/>
          <SectionLine />
        
          <SectionSchedule/>
          <SectionLine />
        
          <SectionContact />
        </div>

        <HtmlFooter inOtherPage={false} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  );
}

export default IndexPage;
