import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './../../App.css';
import './../ListOfPage.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import SliderImage from '../../components/SliderImage';
import { mainPage } from '../../constants/pageName';
import MainBtn from '../../components/MainBtn';

import img04 from './../../assets/images/slide/img04.png';

const FreeAndLaw = () => { 
  useEffect(() => window.scrollTo(0,0), []);

  const navigate = useNavigate();
  const navigateToArticlesList = () => { navigate(mainPage.myArticle); };

	return (
    <div>
      <div id="step-container">
        <div id="articles-info">
          <h1>《強調自由看似保障了所有人的權利，其實使人忽略紀律》</h1>
          <h3>2015年10月2日</h3>
        </div>

        <div id="step-container-body">
          <p className="first">
            於自由、人權、法治三者中，自由的定義最為抽象，並且容易使人混淆，雖然有人會以一段段文字去定義「自由」，但我認為都太過一概而論。「自由」本身涉及太多價值判斷，受太多主觀因素影響。有別於「人權」和「法治」，「人權」有聯合國制定的「人權宣言」；「法治」則是依法治國，這些都易理解得多。
          </p>
          <p className="first">
            很多人試圖從字面上理解「自由」，認為能夠做自己想做的事情就是自由，但其實這是錯的。隨心所欲並非自由。因為你想做的事情其實是受內心感受驅使的，例如你十分口渴，想馬上喝口水，而你可以即時滿足這個欲望，這並不是自由，因為你其實是被口渴的生理現象所影響，才會產生對喝水的欲望，你只不過成了生理現象的奴隸而已。就好像我們會稱一個在半空下墜的物體為自由落體，但實際上它並非「自由」的，它只是受地心吸力這個物理現象影響，我相信當你失足從高處墮下的那一刻，你不會認為自己是自由的，兩者是相同道理。
          </p>
          <p className="first">
            反而忍耐和克制，才是「自由」，因為在那一刻你不再受各種因素影響，奪回對自身的「控制權」，不再成為受內心感受操縱的奴隸。但有多少人明白這個道理？即使我們明白這個道理，又有多少人做到？更何況這只不過是「自由」的其中一個基本概念。多數人都只是膚淺的認為「自由」就是隨心所欲；多數人受金錢、權力、酒精和性慾控制，並不擁有「自由之身」，刻意強調其所謂的「自由」，不過是掩飾自己的醜陋，以此作為一個開脫的藉口。對社會而言，這是十分危險的事情，若然我們未能忍耐克制，堅持走在道德規範的路上，而強調自以為是的「自由」，只會離開正義，違背公義，忽略法治。
          </p>
          <p className="first">
            再者，「自由」於某程度上，和權利存在很多矛盾。人權宣言指出人人生而自由，有權享有生命和人身安全。那「自由」不正是保障了我們的權利嗎？請思考這個問題：「你擁有自身嗎？」你或會答：「當然，既然我享有生命，我就擁有自身。」後續問題：「假如你不完全擁有自身，你還是自由的嗎？」如果你的答案為不是，請考慮以下兩點。
          </p>
          <p className="first">
            首先，依照「自由」的基本概念，理論上可以否定人權宣言中「人人生而自由」之說。因為我們並非生而自由，我們沒法決定自己的口味，以致到出身後受這種先天預設的身理影響，特別喜歡吃某一種食物，或特別討厭某種食物。基因遺傳決定了我們的未來，而我們不能選擇，所以嚴格而言，從我們出身的那一刻，已經失去了一部份的「自由」，因為我只喜歡蘋果味的貴妃糖，而不喜歡咖啡味的，而這個口味的喜好是我自己不能控制的，此其一。
          </p>
          <p className="first">
            另外，若然我們真的擁有自身，我們有對自己身體的「私有產權」，禁止器官買賣的法例已經侵犯了我們的「自由」。因為我們沒有利用我們身體某一部分去獲利的權利和自由，但若然我真的擁有自身，我完全可以在不影響他人的情況下運用自己的身體去從事任何活動。加上急需器官更換的病人並不少，若然我們有一個器官自由買賣的市場，一定可以幫到更多有需要的病人，這甚至是一件善舉。然而，多數人反對器官買賣，因為若果容許開放發售人體器官，其用途根本難以規管；而且以金錢來進行器官交易，並不是一件正確的事情，若硬要給予其一個合法的標籤，道德的底線將不再存在。這亦說明了為何自由必須以法律約束，此其二。
          </p>
          <p className="first">
            「自由」是一個十分深奧的哲學問題，但大多數人沒有透徹理解「自由」，卻強調「自由」，那是一種扭曲，而這種扭曲只有一個目的，那就是逃避法律的約束。
          </p>
          <p className="first">
            美國 — 民主大國，一個「自由國度」。她為了自身利益，大談推廣自由，要在世界各地推行美式民主，鷹目投向了中東，覬覦其石油資源，實行中東民主戰略試圖穩定中東局勢，其「成果」有目共睹：群眾運動、奪權、軍閥鬥爭、社會失控脫序、武裝民兵橫行、恐怖主義和極端主義犯濫、利比亞瀕臨分裂邊緣。中東現時的民主亂局就是由美國一手造成的。民主轉型而繁榮穩定發展的國家少之又少，類似不成功的案例罄竹難書。
          </p>
          <p className="first">
            再回到香港，適逢佔領行動一週年，再次喚起我個人對整個行動的看法。「佔領中環」行動發起人將其定義為公民抗命，藉此將整個行動合理化，試圖佔據道德高地。但佔中稱不上公民抗命，不但是違法的行為，更被政客扭曲人民對「公義」的理解。公民抗命是抗爭的最後手段，「佔中」的目的是爭取真普選，然而，泛民最後集體投下反對票令政改方案遭否決告訴我們，「佔中」絕對不是爭取真普選的最後手段，整個行動不是公民抗命，只是讓部分港人發洩積累已久的怨氣的一次機會，更別說齷齪之輩從中獲利，政治背後的陰暗面。「佔中」之後的行動如衝擊立法會，反水貨客示威者個別偏激行為，甚至連泛民議員都予以強烈譴責，跟他們劃清界線。
          </p>
          <p className="first">
            在強調「自由」的時候，往往不要忽略人的劣根性，有些人的素質就是不配擁有自由，讓他們擁有自由，他們就借機生事，使社會陷入動盪，無辜人民的性命財產安全受到威脅，不論「美式民主」推翻幾多個獨立政權，中東局勢依然緊張，比起美國未介入時更加混亂和複雜。現在美國只能以強硬的軍事手段控制當地局勢。不適當的強調「自由」，不但造成社會動盪，甚至可以剝削其他人生存的權利。
          </p>
          <p className="first">
            言論自由是香港的核心價值，然而有人卻曲解言論自由，將不正確的行為合理化，指鹿為馬、顛倒是非黑白、衝擊法治、破壞本港核心價值。濫用言論自由來強調「自由」，加上傳媒和政棍一唱一和，理想主義沖昏了頭腦，懷著一腔熱血高喊：「生命誠可貴，愛情價更高，若為自由故，兩者皆可拋。」猶如一場自編自導自演的內心戲，並自我感動，陶醉其中，不顧一切，寧知法犯法，也要為這場內心戲演至高潮一刻，然後黯然落幕，未能搏盡掌聲，卻成為新聞材料，讓一眾傳媒朋友不亦樂乎。
          </p>
          <p className="first">
            「自由」是一個偉大的理想，理想和現實總會存在落差，人類有追求自由的原始渴望；但同時人的慾望是無窮無盡的，得一就會想二。這使我們進步，亦使我們有時會「鑽牛角尖」，強調自由而忽略法治的重要性。人權、自由、平等是普世價值，法治、紀律亦是，自由不能保障我們的權利，只有在法律約束下的自由才能。隨心所欲不逾矩，乃為自由之本。不然，自由就會令法治走到窮途末路，然後慢慢地摧毀我們。建基於法治之上的民主高牆，將會在我們面前倒下。
          </p>
          <p className="first">
            前車可鑑，但願我們能吸取歷史教訓，時刻警醒。
          </p>
        </div>

        <br/><br/>

        <div className = 'buttonWrapper'>
          <MainBtn text='Back' onClick={() => navigateToArticlesList()}/>
        </div>
      </div>
    </div>
	)
}

const FreeAndLawPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='隨心所欲不逾矩，乃為自由之本。'
          imgLink={img04}
        />

        <div className='content-container'>
          <FreeAndLaw />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default FreeAndLawPage;