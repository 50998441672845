import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import './../App.css';
import './IdeaPage.css';
import './ListOfPage.css';

import Navbar from '../components/Navbar';
import CusScrollToTop from '../components/CusScrollToTop';
import HtmlFooter from '../components/HtmlFooter';
import BottomScrollProgressBarUI from '../components/BottomScrollProgressBar';
import { QuoteBlockListOfArticlesPage } from '../components/QuoteBlock';
import SliderImage from '../components/SliderImage';
import { articlesPage } from '../constants/pageName';

import img04 from '../assets/images/slide/img04.png';

const ListOfArticlesBody = () => { 
  useEffect(() => window.scrollTo(0,0), []);

	return (
    <div>
      <div id="step-container">
        <h1>Description Articles</h1>
        <hr />

        <div id="step-container-body">
          &nbsp;&nbsp;&nbsp;&nbsp; <Link to={articlesPage.placeInHouse} className="articlesLink">《家中一隅》</Link>
          <br/><br/>
        </div>
      </div>

      <div id="step-container">
        <h1>Review Articles</h1>
        <hr />

        <div id="step-container-body">
          &nbsp;&nbsp;&nbsp;&nbsp; <Link to={articlesPage.gameDesignStudy} className="articlesLink" >《Game Design Comparative Study》</Link>
          <br/><br/>
          &nbsp;&nbsp;&nbsp;&nbsp; <Link to={articlesPage.fear} className="articlesLink">《恐懼》：靈感取至 Alan Wake</Link>
          <br/><br/>
        </div>
      </div>

      <div id="step-container">
        <h1>Perspective Articles</h1>
        <hr />

        <div id="step-container-body">
          &nbsp;&nbsp;&nbsp;&nbsp; <Link to={articlesPage.onePhilosophyProblem} className="articlesLink">《一個現實的哲學問題》</Link>
          <br/><br/>
          &nbsp;&nbsp;&nbsp;&nbsp; <Link to={articlesPage.nightReading} className="articlesLink">《夜讀有感》</Link>
          <br/><br/>
        </div>
      </div>
      
      <div id="step-container">
        <h1>Short Story</h1>
        <hr />

        <div id="step-container-body">
          &nbsp;&nbsp;&nbsp;&nbsp; <Link to={articlesPage.manInCorner} className="articlesLink">《角落裡的人》</Link>
          <br/><br/>
          &nbsp;&nbsp;&nbsp;&nbsp; <Link to={articlesPage.hijack} className="articlesLink">《劫機》</Link>
          <br/><br/>
        </div>
      </div>

      <div id='step-container'>
        <QuoteBlockListOfArticlesPage />
      </div>
    </div>
	)
}

const ListOfArticlesPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='One day I will find the right words, and they will be simple. Jack Kerouac'
          imgLink={img04}
        />

        <div className='content-container'>
          <ListOfArticlesBody />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default ListOfArticlesPage;