////////// ////////// //////////
// Scroll to section and activate animation
////////// ////////// //////////
function scrollFunction() {
  if (document.getElementById("hAboutMe") === null) { // if not at index page
    return;
  }

  if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
    document.getElementById("hAboutMe").className = "fadeCustom";
    document.getElementById("pAboutMe").className = "slideInLeft";
    document.getElementById("sectionAboutMeRight").className = "slideInBottom";
    document.getElementById("sectionAboutMeRowChild").className = "slideInRight";
  } else {
    document.getElementById("hAboutMe").className = "hidden";
    document.getElementById("pAboutMe").className = "hidden";
    document.getElementById("sectionAboutMeRight").className = "hidden";
    document.getElementById("sectionAboutMeRowChild").className = "hidden";
  }

  if (document.body.scrollTop > 1200 || document.documentElement.scrollTop > 1200) {
    document.getElementById("hSkill").className = "fadeCustom";
    document.getElementById("pSkill").className = "slideInLeft";
  } else {
    document.getElementById("hSkill").className = "hidden";
    document.getElementById("pSkill").className = "hidden";
  }

  if (document.body.scrollTop > 2800 || document.documentElement.scrollTop > 2800) {
    document.getElementById("pSkill2").className = "slideInBottom";
  } else {
    document.getElementById("pSkill2").className = "hidden";
  }

  if (document.body.scrollTop > 3500 || document.documentElement.scrollTop > 3500) {
    document.getElementById("hHobby").className = "fadeCustom";
    document.getElementById("pHobby").className = "slideInLeft";
  } else {
    document.getElementById("hHobby").className = "hidden";
    document.getElementById("pHobby").className = "hidden";
  }

  if (document.body.scrollTop > 4600 || document.documentElement.scrollTop > 4600) {
    document.getElementById("hSchedule").className = "fadeCustom";
    document.getElementById("pSchedule").className = "slideInLeft";
  } else {
    document.getElementById("hSchedule").className = "hidden";
    document.getElementById("pSchedule").className = "hidden";
  }

  if (document.body.scrollTop > 5500 || document.documentElement.scrollTop > 5500) {
    document.getElementById("hContact").className = "fadeCustom";
    document.getElementById("pContact").className = "slideInLeft";
  } else {
    document.getElementById("hContact").className = "hidden";
    document.getElementById("pContact").className = "hidden";
  }
}   

function scrollAnimation(scrollAnimationDisable) {
  if (scrollAnimationDisable.matches === false) {
    window.onscroll = function() {
      scrollFunction();
    };
  }
}

if ( window.location.pathname === '/' ){ // only call in index page
  var scrollAnimationDisable = window.matchMedia("(max-width: 850px)");
  scrollAnimation(scrollAnimationDisable); // Call listener function at run time
  scrollAnimationDisable.addListener(scrollAnimation); // Attach listener function on state changes
}
