import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';

import './../../App.css';
import './../IdeaPage.css';
import './studyPage.css';
import './../../components/CodeBlock.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import CodeBlock, { codeBlockmode, codeBlocktheme } from '../../components/CodeBlock';

import { 
  dockerfile,
  dockeryml,
} from '../../constants/reactAceCodeText';

import docker1 from '../../assets/images/study/Docker1.png';
import docker2 from '../../assets/images/study/Docker2.png';

const DockerDeployBody = () => {
  useEffect(() => window.scrollTo(0,0), []);

	return (
    <div className='step-container'>
      <h1>How Docker Work</h1>
      <hr/>
      <div className='step-container-body'>
        <div id='study-contents'>
          <h2>What is Docker?</h2>
          Please consider the follow situation:
          <ol>
            <li>You created an Application which is working fine in your machine</li>
            <li>But in production it doesn't work properly (which happens a lot)</li>
            <li>That is when the Developer's famous words are spoken: <b>It works on my machine</b></li>
          </ol>
          But why? It could be due to:
          <ul>
            <li>Dependencies</li>
            <li>Libraries and versions</li>
            <li>Framework</li>
            <li>OS Level features</li>
            <li>Microservices</li>
          </ul>
          That the developers machine has but not there in the <b>production environment</b>. 
          We need a standardized way to package the application with its dependencies and deploy it on any environment. <b>Docker</b> is a tool designed to make it easier to:
          <ul>
            <li>Create</li>
            <li>Deploy</li>
            <li>Run</li>
          </ul> 
          applications by using <b>containers</b>.
          <p>
            Docker packages an application and all its <b>dependencies</b> in a <b>virtual container</b> that can run on any Linux server.
          </p>
          <img src={docker1}  alt='virtual container'/>
          <p>
            Each container runs as an <b>isolated process</b> in the user space and take up less space than regular VMs due to their layered architecture.
          </p>
          <img src={docker2} style={{maxWidth: '80%'}}  alt='layered architecture'/>
        </div>

        <div id='study-contents'>
          <h2>Example: Dockerizing PHP Application</h2>
          
          <h3>Step 1: Install and Step up Docker</h3>
          <p>
            Vist <a href='https://www.docker.com/' target='_blank' rel='noreferrer'>Docker</a> official website for details. 
            You can also install <a href='https://www.docker.com/products/docker-desktop/' target='_blank' rel='noreferrer'>Docker Desktop</a> if you want to use the GUI.
          </p>
          
          <h3>Step 2: Create Dockerfile and docker-compose.yml</h3>
          We will need 3 images:
          <ol>
            <li>php:apache</li>
            <li>mysql</li>
            <li>phpmyadmin/phpmyadmin</li>
          </ol>
          <p>Details: <a href='https://hub.docker.com/' target='_blank' rel='noreferrer'>Docker Hub</a></p>
          For Dockerfile:
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.xml}
              theme={codeBlocktheme.monokai}
              id={'dockerfile'}
              value={dockerfile}
              style={{ width: '100%', height: '150px' }}
            />
          </div>
          For docker-compose.yml:
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.xml}
              theme={codeBlocktheme.monokai}
              id={'dockeryml'}
              value={dockeryml}
              style={{ width: '100%', height: '650px' }}
            />
          </div>

          <h3>Step 3: Build and Run</h3>
          Under your project directory, run:
          <div id='cmdline'>docker-compose build</div>
          <div id='cmdline'>docker-compose up</div>

          <h3>Step 4: Test</h3>
          <div id='cmdline'>localhost:8080/index.php</div>
        </div>
      </div>
    </div>
	)
}

const DockerDeploy = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <div className='content-container'>
          <DockerDeployBody />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default DockerDeploy;