import './SliderImage.css'

type sliderImageProps = {
  captionText: string,
  imgLink: any,
}

const SliderImage = (props: sliderImageProps) => {
  return (
    <>
      <div className='slideshow-container'>
        <img src={props.imgLink} style={{width: '100%'}} alt='' />
        <div className='text'>{props.captionText}</div>
      </div>
      <br/>
    </>
  )
}

export default SliderImage;
