import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React from 'react';

import './../App.css';
import './SectionContact.css';
import { QuoteBlockIndexPage } from '../components/QuoteBlock';

const SectionContact = () => {
	return (
    <div className='pageSection' id='sectionContact'>
      <div className='sectionContactsub'>
        <h2 id='hContact'>Contact</h2>
        <p id='pContact'>
          Please feel free to contact me. &#x261B;
          <br/><br/><br/>
          &#x2709; Email Address: <br/><strong>laijason03@gmail.com</strong>
          <br/><br/><br/>
          &#x260E; My Phone Number: <br/><strong>(+852) 6611 9419</strong>
        </p>

        <br/><br/><br/>
        <br/><br/><br/>
        <br/><br/><br/>

        <QuoteBlockIndexPage />
      </div>
    </div>
	)
}

export default SectionContact;