import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './../../App.css';
import './../ListOfPage.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import SliderImage from '../../components/SliderImage';
import { mainPage } from '../../constants/pageName';
import MainBtn from '../../components/MainBtn';

import img04 from './../../assets/images/slide/img04.png';

const NightReading = () => { 
  useEffect(() => window.scrollTo(0,0), []);

  const navigate = useNavigate();
  const navigateToArticlesList = () => { navigate(mainPage.myArticle); };

	return (
    <div>
      <div id="step-container">
        <div id="articles-info">
          <h1>《夜讀有感》</h1>
          <h3>2014年10月30日</h3>
        </div>

        <div id="step-container-body">
          <p className="first">
            夜，本應是寧靜幽怡的，然而，夜帶給我的，只有壓迫感。因為夜晚在我眼中只有一個意義，那就是新的一天即將開始。
          </p>
          <p className="first">
            夜和都市顯得格格不入，即使到了夜晚，一天的工作才剛開始。
          </p>
          <p className="first">
            已經是深夜十二點半，我依然埋頭苦幹，只為了明天做足準備。那是一場和疲倦的耐力戰，而「獎品」是頭昏腦脹的滋味。面對那堆積如山的筆記，以及過往三十年的歷屆試題，要單獨應戰實是難以取勝，所以咖啡因和止痛藥永遠是最佳戰友。將知識硬生生塞入腦袋是我們用時間和健康換來的結果，亦是香港學生們的「優良傳統」。
          </p>
          <p className="first">
            已經是凌晨一時多，我望著手上的文憑試中文作文試題，其中一條題目是：「孩子不是等待被填滿的瓶子，而是盼望化作燃燒的火焰」。我不禁苦笑，很難想像那一屆考生懷著多麼矛盾的心情去寫這篇文章。
          </p>
          <p className="first">
            在這個教育制度下，我們的確培育出多個成績優異的學生，因為經歷無數的考試和測驗後，學生們的答題技巧已經去到爐火純青的程度，足以應付那些「鑽牛角尖」的試題；在這個教育制度下，所有的教育產業，如補習社，教授學生的已經不再是知識，而是技巧和規則；在這個教育制度下，創意和革新的原素已經不復存在，剩下的只有處理各類題型的固定思維；在這個教育制度下，無數學生的理想和志願被扼殺；在這個教育制度下，我們最終得到些甚麼？一個被填滿的瓶子。
          </p>
          <p className="first">
            我脫下眼鏡，用手揉揉雙眼，然後過了好一會兒才看到東西，起初不習慣時還以為自己要瞎了，害怕得很，但久而久之就慣了。不知從那時開始，睡眠已經成為了奢侈的享受；不知從那時開始，時間漸漸不夠用；不知從那時開始，我再也不能重拾夜讀時的那種放鬆心情。
          </p>
          <p className="first">
            已經是凌晨三時，咖啡也起不了作用，我的頭痛得要命，卻不敢再吃止痛藥了，始終對身體不好。眼前的文字愈來愈模糊，慢慢地，燈光和文字化作一團，眼皮開始不聽使喚的往下墜……看來，該睡了。但當我合上眼睛時，那些公式、題目又再次在我腦海中浮現，如一條條鐵鏈纏繞著我的腦袋。在這道鉫鎖下，心田早已乾涸了，靈感和創意早已枯萎了，感覺早已變得麻木了。
          </p>
          <p className="first">
            就這樣地，帶著壓迫感入睡。
          </p>
        </div>

        <br/><br/>

        <div className = 'buttonWrapper'>
          <MainBtn text='Back' onClick={() => navigateToArticlesList()}/>
        </div>
      </div>
    </div>
	)
}

const NightReadingPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='在這個教育制度下，我們最終得到些甚麼？'
          imgLink={img04}
        />

        <div className='content-container'>
          <NightReading />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default NightReadingPage;