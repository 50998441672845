export const mainPage = {
  timeline: '/WorkTimelinePage',
  studyMaterial: '/StudyPage',
  webDev: '/WebDev',
  gameDev: '/GameDev',
  myArticle: '/MyArticle',
  myPiano: '/MyPiano',
  gameUnity: '/GameUnity',
  webList: '/WebList'
}

export const studyMaterialPage = {
  s3: '/S3',
  ec2: '/EC2',
  sorting: 'http://3.145.152.71/SortingAlgorithms',
  dockerDeploy: '/DockerDeploy',
  bookmarkingService: '/BookmarkingService',
}

export const articlesPage = {
  gameDesignStudy: '/GameDesignStudy',
  onePhilosophyProblem: '/OnePhilosophyProblem', // 一個現實的哲學問題
  hijack: '/Hijack', // 劫機
  nightReading: '/NightReading', // 夜讀有感
  placeInHouse: '/PlaceInHouse', // 家中一隅
  freeAndLaw: '/FreeAndLaw', // 強調自由看似保障了所有人的權利，其實使人忽略紀律
  fear: '/Fear', // 恐懼
  manInCorner: '/ManInCorner', // 角落裡的人
  feed: '/Feed', // 飼料
}

export const gamesPage = {
  aiHackGame: '/AIHackGame',
  initialEdge: 'https://drive.google.com/file/d/1oTU-OZ2KYA_UKSIApnXCXGwk0TpJBXxY/view?usp=sharing'
}

export const myWebpages = {
  sortingAlgorithms: 'http://3.145.152.71/SortingAlgorithms',
  onlineShopping: 'http://3.145.152.71/OnlineShopping',
}