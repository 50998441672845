import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import './../App.css';
import './IdeaPage.css';

import Navbar from '../components/Navbar';
import CusScrollToTop from '../components/CusScrollToTop';
import HtmlFooter from '../components/HtmlFooter';
import BottomScrollProgressBarUI from '../components/BottomScrollProgressBar';
import { CollapsibleViewGame } from '../components/CollapsibleView';
import SliderImage from '../components/SliderImage';
import { QuoteBlockGameIdeaPage } from '../components/QuoteBlock';

import modelling from '../assets/images/game/3d modelling example.png';
import animation from '../assets/images/game/animation example.png';
import classDiagram from '../assets/images/game/class diagram.png';
import uiHub from '../assets/images/game/UI Hub.png';
import ui from '../assets/images/game/UI.png';
import img03 from '../assets/images/slide/img03.png'
import { mainPage } from '../constants/pageName';

const GameIdeaPageBody = () => {
  useEffect(() => window.scrollTo(0,0), []);

	return (
    <div>
      <div className='step-container'>
        <h1>Things That You Need to Know</h1>
        <hr/>

        <div className='step-container-body'>
          <h2 style={{color: 'red'}}>
            Warning: This is not a tutorial on Unity and Blender.
            It only provide a general idea on game development.
          </h2>

          <ul style={{listStyleType: 'none'}}>
            <li><b style={{fontSize: '30px'}}>Q</b>: Is there other tools that I can use to create my own games?</li>
            <li><b style={{fontSize: '30px'}}>A</b>: Yes, there are other tools such as unreal engine that you can use to create your own games.</li>
            <br/>
            <li><b style={{fontSize: '30px'}}>Q</b>: Are Unity and Blender hard to learn?</li>
            <li><b style={{fontSize: '30px'}}>A</b>: It requires some basic knowledge in programming, it maybe difficult at first but things will get easier after you learn the basic concepts.</li>
            <br/>
            <li><b style={{fontSize: '30px'}}>Q</b>: Is there any other tools are needed?</li>
            <li><b style={{fontSize: '30px'}}>A</b>: Mostly Photoshop are needed too, but it really depands on your project.</li>
            <br/>
            <li><b style={{fontSize: '30px'}}>Q</b>: If I want to learn Unity and Blender, where should I start?</li>
            <li><b style={{fontSize: '30px'}}>A</b>: For Unity, <a href='https://learn.unity.com/' target='_blank' rel='noreferrer'>unity Learn</a> will be a good start for you. For Blender, I suggest you find a YouTuble video and follow it to make your first 3D model.</li>
          </ul>
        </div>
      </div>

      <div className='step-container'>
        <h1>Basic Understanding</h1>
        <hr />
        <div className='step-container-body'>
          <p>How It Works.</p>
          <table id='webtable'>
            <thead>
              <tr>
                <th>Tool</th>
                <th>Functions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{width: '30%'}}>Unity</td>
                <td style={{width: '70%'}}>Physics, C# script to control player behaviour, bots AI, 3D or 2D modelling(basic), animations(basic) etc.</td>
              </tr>
              <tr>
                <td style={{width: '30%'}}>Blender</td>
                <td style={{width: '70%'}}>3D modelling(advance), animations(advance), shading etc.</td>
              </tr>
              <tr>
                <td style={{width: '30%'}}>Photoshop</td>
                <td style={{width: '70%'}}>Create 2D textures, 2D objects etc.</td>
              </tr>
              <tr>
                <td style={{width: '30%'}}>GitHub</td>
                <td style={{width: '70%'}}>Well you will need it (or other similar things), trust me. If you don't know how it works, you better learn it first. Things will become a lot easier with that.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className='step-container'>
        <h1>Step 1: Prototyping</h1>
        <hr />
        <div className='step-container-body'>
          <div>
            Let's suppose you already equip the skills of game development,
            and start to create your first game.
          </div>
          <div>
            As always, prototyping is an important step to start every projects.
            You should always starting with this step first, 
            otherwise the structure of your final product will become totally mess.
            Especially the Unity is using OOP approach in scripting,
            you not only need to think of what you need in the game here,
            but also to come up with a very basic className diagram with clear structure.
            <br/><br/>
            You can refer to the diagram below in case that you forget how it looks like.
            <br/><br/>

            <figure>
              <img src={classDiagram} style={{width: '95%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} alt=''/>
              <figcaption style={{textAlign: 'center', fontSize: '16px'}}>Example of a className diagram for the online shopping website</figcaption>
            </figure>
              
            <br/><br/>
            I am not going to discuss too much on the detail.
            Basically the className diagram is showing you the relationship between each className.
            You would come up with a big picture on what you are going to do in the project.
            <br/><br/>
            After finishing the className diagram, 
            you also need to desgin the UI and take notes on what functions are needed to be included.
            Normally the game should included these things:
            <br/><br/>
            For the UI:
            <ul>
              <li>Main Menu</li>
              <li>Option Menu</li>
              <li>Hub (in game)</li>
            </ul>
            <br/>
            Game system:
            <ul>
              <li>Player Controller</li>
              <li>Bots AI</li>
              <li>Save/Load</li>
            </ul>
            <br/>
            It really depends on what you are going to make. 
            But having the idea and take the notes first is always a good practice.
          </div>
          <div>
            You can also come up with the basic idea of the game in this stage.  
            For example you can think of the style of the game, the theme and background, as well as some basic setting of the game. 
            Then you can draw the prototype on the paper, so that you can create the scene referring to the pencil prototype in the developing stage.
          </div>
        </div>
      </div>

      <div className='step-container'>
        <h1>Step 2: Get Ready to Start</h1>
        <hr />
        <div className='step-container-body'>
          <p style={{fontSize: '25px'}}>Things that you need: </p>
          <ul>
            <li>An Editor: Could be Notepad++ or Visual Studio Code or others</li>
            <li>Tools: Unity, Blender, Photoshop etc.</li>
            <li>A GitHub account</li>
          </ul>
          <p style={{fontSize: '25px'}}>
            Make it more structural and systematic...you will put most of the files inside the Assets folder.
          </p>
          <ul>
            <li>Main folder: GameProject</li>
            <li>/GameProject/Assets: </li>
            <ul>
              <li>1 _Prefab folder: all prefabs in here</li>
              <li>1 Animation folder: all animations in here</li>
              <li>1 Resources folder: all resources (i.e. BGM) in here</li>
              <li>1 Scenes folder: all game scenes put here</li>
              <li>1 Scripts folder: all C# scripts put here</li>
              <li>1 2D Sprites/3D Model folder: all 2D or 3D objects put here</li>
              <li>1 Effects folder: all game effects put here</li>
              <li>1 External Assets folder: all external assets put here</li>
            </ul>
          </ul>
          
          <p style={{fontSize: '25px'}}>File Tree (Click to expand)</p>
          <CollapsibleViewGame />

          <p>
            After you have created the empty project and folders,
            DO NOTHING! Don't start you project now.
            Open your GitHub, create a repository and clone it to the local.
            Put the empty unity project to the local directory, 
            so that your project can be synchronized with your git remote desktop.
            The advantage of doing that is you can track you code a lot easier.
          </p>
        </div>
      </div>

      <div className='step-container'>
        <h1>Step 3: UI - Game System - Artworks</h1>
        <hr />
        <div className='step-container-body'>
          <p>Now you can develop your game according to your prototype.</p>

          <p style={{fontSize: '25px'}}>UI</p>
          <div>
            Me personally would begin with the UI first.
            Make a simple and rough UI with button and text element first.
            Ensure the event system is organized and flexible,
            so that you can easily add other functions and components to it during the development stage.
            A clear and simple UI will help you a lot in development stage,
            especially for testing,
            because the visualized elements are always better than the text base elements.

            <br/>
            <figure>
              <img src={ui} style={{width: '95%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} alt=''/>
              <figcaption style={{textAlign: 'center', fontSize: '16px'}}>
                The example of a 'simple and rough' UI.
                (This is the example from the project 'Artificial Intelligence')
              </figcaption>
            </figure>
            <br/>

            <br/>
            <figure>
              <img src={uiHub} style={{width: '95%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} alt=''/>
              <figcaption style={{textAlign: 'center', fontSize: '16px'}}>
                The example of a Hub UI.
                (This is the example from the project 'Artificial Intelligence')
              </figcaption>
            </figure>
            <br/>
          </div>

          <p style={{fontSize: '25px', paddingTop: '20px'}}>Game System</p>
          <div>
            Then is the Game System, to be more clear is mainly related to backend stuffs,
            such as the game mechanisms (bots AI, player data, skills, items, die, retry etc.).
            You don't need to care about the Artworks (3d modelling, animations, music etc.) in this stage,
            If you want to test the Game System,
            you can simple use a cube object to represent your character, 
            and attach the C# script to it. 
            You should follow your className diagram in the prototype stage when developing the Game System.
          </div>

          <p style={{fontSize: '25px', paddingTop: '20px'}}>Artworks</p>
          <div>
            After the UI and Game System are solid, you can start the Artworks design stage.
            I personally suggest you start with character (or enemies or other game objects) first, then is the game map design, UI design, post effects and sound effect accordingly.
            If you are making 3d game, you should also beware of the shader when creating the model.
          </div>

          <p style={{fontSize: '25px', paddingTop: '20px'}}>Artworks / 3D Modelling</p>
          <div>
            There are three main steps in creating a 3D model:
            <ol>
              <li>
                3D modelling: Use the vertices in mesh object to draw the outline of the 3D object based on the image reference.
              </li>
              <li>
                Object Combining: Combine different mesh object in a reasonable way. 
                For example, if two square cubes are used to create the body of the house, 
                the two squares should be joined for better optimization and manipulation of the 3D object.
              </li>
              <li>
                Texturing: Select the appropriate texture or color for the corresponding part of 3D object. 
                For some special texture, you can use Photoshop to create the texture, then import to the unity and add the texture to the 3D object.
              </li>
            </ol>
              
            <br/>
            <figure>
                <img src={modelling} style={{width: '95%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} alt=''/>
                <figcaption style={{textAlign: 'center', fontSize: '16px'}}>
                  From character design to modelling. 
                  The Head-Body Ratio has been adjusted in modelling stage due to complexity of modelling and low-poly style matching.
                  (This is the example from the project 'Initial Edge', my groupmate Matthew Ho handle the character design mainly)
                </figcaption>
            </figure>
            <br/>
          </div>

          <p style={{fontSize: '25px', paddingTop: '20px'}}>Artworks / 3D Animations</p>
          <div>
            You need to add animations to the 3D object created.
            There are two main steps to do so: first is <strong>Character Rigging</strong>; second is <strong>Animation</strong>.
            For the part of character rigging, you need to create the bone (Armature) for the character, 
            and 'attach' the bone to the mesh object so when the bone is moving, 
            the mesh object will move with it. 
            After finishing the character rigging, you proceed to the animation part. 
            You can move the bone in 'Pose Mode' in blender and create different keyframe in timeline. 
            You can also use the Dope Sheet Editor in Blender to shift the time of different keyframe in order to get the effect you want. 
            Use the Graph Editor to create the cycle of the animation (e.g. walking cycle, fighting cycle). 
            You can also use the NLA Editor to create different animations.

            <br/>
            <figure>
              <img src={animation} style={{width: '95%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} alt='' />
              <figcaption style={{textAlign: 'center', fontSize: '16px'}}>
                3D modelling, Rigging and Animation (running cycle) of the final boss: Unicorn.
                (This is the example from the project 'Initial Edge', I handle the enemies design mainly)
              </figcaption>
            </figure>
            <br/>
          </div>

          <p style={{fontSize: '25px', paddingTop: '20px'}}>Testing, Debugging, Documentation</p>
          <div>
            Well, I didn't mention it at the beginning, but if you make it this far, now is the final stage before releasing the final product.
            It is a necessary and important part in game development.
            You need to build the game and play the game to see if there is any unexpected event. 
            If any of it occurs, you need to change it and fix it in order to improve the gaming experience. 
            No one would like to play a game that is full of bugs and errors.
            You can also ask your peers to test it with you too.
            More people involving will make this process much more effective.  
          </div>
        </div>   
      </div>

      <div className='step-container'>
        <h1>My Created Games</h1>
        <hr />
        <div className='step-container-body'>
          <div>
            <Link to={mainPage.gameUnity}>
              <Button variant="outlined">View My Created Games</Button>
            </Link>
          </div>
        </div>   
      </div>

      <div className='step-container'>
        <QuoteBlockGameIdeaPage />
      </div>
    </div>
	)
}

const GameIdeaPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='Making Your First Video Game Using Unity And Blender'
          imgLink={img03}
        />

        <div className='content-container'>
          <GameIdeaPageBody />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default GameIdeaPage;