import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './../../App.css';
import './../ListOfPage.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import SliderImage from '../../components/SliderImage';
import { mainPage } from '../../constants/pageName';
import MainBtn from '../../components/MainBtn';

import img04 from './../../assets/images/slide/img04.png';

const ManInCorner = () => { 
  useEffect(() => window.scrollTo(0,0), []);

  const navigate = useNavigate();
  const navigateToArticlesList = () => { navigate(mainPage.myArticle); };

	return (
    <div>
      <div id="step-container">
        <div id="articles-info">
          <h1>《角落裡的人》</h1>
          <h3>2014年11月1日</h3>
        </div>

        <div id="step-container-body">
          <p className="first">
            經濟不景，為了節省生活開支，我搬到一個遠離市中心，較偏僻的住宅區，那兒的租金較便宜，但生活環境十分惡劣，樓下是一條骯髒不堪的小巷，交通也很不方便。
          </p>
          <p className="first">
            自金融風暴後，買樓的人愈來愈少，白忙了一整天，一單交易也沒有。我拖著疲倦的影子，垂頭喪氣地回「家」。經過那條小巷，只見有個中年的露宿者，薘頭亂髮、穿著一件破舊的襯衫和牛仔褲，頹唐的靠在牆角，左手啤酒，右手香菸。令我奇怪的是，在他身旁還放著一個小提琴。他盯了我一眼，我也沒說甚麼，瞄了瞄信箱，然後直接開門上樓梯。
          </p>
          <p className="first">
            回到家中，扭開電視，新聞播著慘不忍睹的市況，以及那些評論員發表「偉論」，甚麼房地產泡沫爆破、政府貨幣政策短視、金融衍生工具籃管不足等，通通都是馬後炮，令人心煩，乾脆關掉電視機，在沙發小睡片刻。睡著睡著，竟聽到樓下傳來小提琴的聲音，我走近窗口，只見樓下小巷子那個中年男人，在拉著他的小提琴。柔和的燈光照著他花白的頭髮，閃閃發光。我仔細地聽，曲子起始時激昂澎湃，如千軍萬馬，所向披靡；到中段則憂怨斷腸，如王者隕歿，萬物死寂，令人閒之悲傷；尾段由陰轉睛，如越過幽谷，柳暗花明又一村。其他住戶都關上窗，只有我一人在聽。很快，一曲奏完，我很欣賞他的才藝，但我並沒有拍掌，他望一望我，仿佛知道我是那唯一的聽者。然而，他依然沒說些甚麼，繼續抽那根香菸。
          </p>
          <p className="first">
            第二天，我費盡唇舌，終於說服了一個買家，是三個星期以來的唯一一單交易。回到家，依然看到那個中年露宿者在那條小巷，我拿了支啤酒，椅在窗邊。沒過多久，他又拉起他的小提琴來。我仔細地聽，這次的節奏比較輕快，如微風吹拂面頰，帶著清新的鄉村氣息，聽完令人心情放鬆。一曲奏完，我沒有拍掌，他望一望我，我只向他點了點頭，擧一舉手中的啤酒，他依然沒說些甚麼，繼續抽菸。
          </p>
          <p className="first">
            如是者過了三個月，同樣的事情重複地發生，那人每天都會奏不同的曲子，而我一如以往地做他唯一的聽者。隨著經濟刺激法案、經濟穩定緊急法案出台、聯儲備局出手、央行降低利率，市況依然低迷，但總算比起幾個月前的一蹶不振為好。公司在同業紛紛宣佈陣亡之際，成功渡過最黑暗的時期。
          </p>
          <p className="first">
            不知不覺，快到聖誕節了，天氣漸冷，我上班時也要穿一件厚厚的風褸。在回家的路上，街上彌漫著濃厚的節日氣氛，廣場一棵巨型聖誕樹上掛滿七彩繽紛的燈飾、媽媽為孩子整理身上的厚衣裳、情侶在街上親熱取暖、十幾個基督徙在唱聖詩……看來金融風暴並沒有影響人們慶祝聖誕節。我邊走邊用手機回覆信息，忽然屏幕上多了幾滴水點，我攤開手，只見一點點白色附在衣絨上……「媽媽，下雪了！」街上的人紛紛抬頭，我望向天空，只見白色的雪花緩緩飄落，街上的人都歡呼起來，那是今年的第一場雪。
          </p>
          <p className="first">
            我呆了一陣，繼續走回家。聖詩在耳邊迴盪，然後漸漸散去……我忽然想到那個露宿者了。經過那條巷子，我看到那個露宿者，他顯然有點冷，全身抽搐著，抱著他的小提琴，蜷縮在角落。我開始有點擔心，脫下風褸，披在他身上，並打電話報警。他指著垃圾桶旁的地下，是一包煙。我便用火柴點著那剩下的一根菸，遞到他嘴邊，他吸了一大口，然後拿起他的小提琴，又拉了起來。那是一首聖誕樂曲，琴音有點遲鈍。
          </p>
          <p className="first">
            很快，曲奏完，他望一望我，只是微微一笑，依然沒說些甚麼……
          </p>
          <p className="first">
            之後，我就再也沒有聽到小提琴的聲音，也沒有再見到那個露宿者。小巷的角落裡，漆黑一片，那街燈已經壞了很久了，依然沒有人來修。我呆呆的在窗邊，仿佛這已經成為了我生活的一部份……
          </p>
          <p className="first">
            在一個偶然的機會下，我閱讀報紙，得知那個露宿者原來是一個過氣的小提琴家，他有一樣先天缺陷，是一個啞巴。
          </p>
        </div>

        <br/><br/>

        <div className = 'buttonWrapper'>
          <MainBtn text='Back' onClick={() => navigateToArticlesList()}/>
        </div>
      </div>
    </div>
	)
}

const ManInCornerPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='然而，他依然沒說些甚麼...'
          imgLink={img04}
        />

        <div className='content-container'>
          <ManInCorner />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default ManInCornerPage;