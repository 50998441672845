import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './../../App.css';
import './../ListOfPage.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import SliderImage from '../../components/SliderImage';
import { mainPage } from '../../constants/pageName';
import MainBtn from '../../components/MainBtn';

import img04 from './../../assets/images/slide/img04.png';

const Feed = () => { 
  useEffect(() => window.scrollTo(0,0), []);

  const navigate = useNavigate();
  const navigateToArticlesList = () => { navigate(mainPage.myArticle); };

	return (
    <div>
      <div id="step-container">
        <div id="articles-info">
          <h1>《飼料》</h1>
          <h3>2020年6月25日</h3>
        </div>

        <div id="step-container-body">
          <p className="first">
            在綠意盎然的草原上，有很多大大小小的農場。其中甲、乙兩個大農場，彼此是競爭對手。農場甲一直以先進的技術和完善的經營方式而擁有更大的競爭力和市佔率。最近，農場乙從農場丙新購入了一批新的豬，為了試驗一種新的飼養技術，將牠們跟自家的豬分開養。農場甲的主人見到了，笑農場乙的主人多此一舉，並不以為然。隨著時間一日一日過去，農場乙的新方式取得了不錯的成果，並因此而賺取了不少利潤，這亦使農場乙展現出日益愈增的野心。農場甲的主人看在眼內，心感不妙。為了確保自身在市場的競爭力，農場乙的擴張必須被制止。於是農場甲主人生出一計，買通農場乙的一些員工，並讓他們給農場乙的豬餵食另一種飼料。由於農場乙的自家豬被鎖在豬舍中，並且有大狼狗看守，實是無從下手。但試驗新飼養技術的豬卻是養在露天的豬圈中，並且只有較溫馴的牧羊犬守著，故此，受賄的農場乙員工在夜裡給這些豬餵食從農場甲帶來的飼料，而其他的農場乙員工則在日間給這些豬餵食原本的試驗飼料。
          </p>
          <p className="first">
            過了一段時間，試驗豬圈裏的豬竟然打起來了，原因是一些豬覺得日間的飼料比較好吃，一些豬則覺得夜裡的飼料比較好吃。牧羊犬忙著維持秩序，不小心咬傷了一些「夜間派」的豬，使「夜間派」的豬更加進入狂暴狀態，連牧羊犬也照打無誤。負責試驗計劃的農場經理對此束手無策，請求農場主人協助，農場主人迫於無奈，把一些大狼狗也調來看守試驗豬圈。然而，一些豬為了吃上農場甲的飼料，紛紛逃出農場乙，「跳槽」到農場甲去。
          </p>
          <p className="first">
            於此一事中，農場乙損失了一些經濟利益，農場甲則感到十分得意。農場乙對農場甲頗為不妥，但大家還是繼續在同一個草原上經營各自的農場。幾個月後，農場甲和農場乙的豬被帶到屠宰場，彼此都成了富人們餐桌上的美食。
          </p>
          <p className="first">
            其實大家都是豬，只不過吃上不同的飼料：「日間派」喜歡吃「紅旗」牌的，「夜間派」則喜歡吃「美蒂」牌的。成份不同但性質一樣，都是給豬吃的飼料。一些豬覺得「紅旗」牌的比較好吃，一些豬則覺得「美蒂」牌的比較好吃，只不過是口味的問題，飼料本質並無不同，豬的結局亦是同樣。「就算是這樣，有得選擇的話我也寧願做農場甲的豬，起碼生前吃得好！」嗯，有道理，一隻豬想食好的東西，並無不妥……但有得選擇的話，我寧願做農場主或是那個在高級餐廳吃豬排的人。如果你的目標只是想食上你認為比較好味的飼料的話，很遺憾，你永遠都只會是一隻豬。
          </p>
          <p className="first">
            世界的運作很簡單，大多數人都是一隻豬，在吃農夫餵的飼料。農夫把豬養肥之後，將豬賣出去，加工後變成食物。富人需要食豬肉，農夫需要富人的錢來經營農場，豬則需要農夫的飼料生存。
          </p>
          <p className="first">
            當然你可以說大家是互相依賴，所以豬的感受也很重要……因為沒有豬的話，農夫就沒有錢經營農場，農場會倒閉，富人會餓死……如此種種都似乎在突顯豬的價值，讓牠們認為自己可以討價還價。但你忽略了一點，草原上並不只有一個農場，即使豬只是唯一的食物，富人們還有其他選擇；就算這個農場的農夫和豬都死光光，他們還是可以吃到豬肉的。如果你夠聰明，你現在應該知道，左右農場主的並不是這些豬，而是富人。
          </p>
          <p className="first">
            一隻獅子是不會關心獵物的感受。每個人都應該認清自己的價值，豬的價值在於貢獻、農夫的價值在於經營、富人的價值在於金錢。如果你不想做一隻豬，就要提供比豬貢獻本身更大的價值；但如果你不想做一隻豬的同時，不作貢獻，還希望有比豬更高級的待遇，那你能夠提供的價值就比豬本身更低。簡單而言，你連豬都不如，你只配成為飼料。
          </p>
        </div>

        <br/><br/>

        <div className = 'buttonWrapper'>
          <MainBtn text='Back' onClick={() => navigateToArticlesList()}/>
        </div>
      </div>
    </div>
	)
}

const FeedPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='每個人都應該認清自己的價值'
          imgLink={img04}
        />

        <div className='content-container'>
          <Feed />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default FeedPage;