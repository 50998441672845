import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { Unity, useUnityContext } from 'react-unity-webgl';
import { isMobile } from 'react-device-detect';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DownloadIcon from '@mui/icons-material/Download';

import '../../App.css';
import './../IdeaPage.css';

import HtmlFooter from '../../components/HtmlFooter';
import CusDialog from '../../components/CusDialog';
import { unityContexts } from '../../constants/unityContents';
import { Button } from '@mui/material';

const AIHackGameBody = () => {
  useEffect(() => window.scrollTo(0,0), []);

  const { unityProvider, requestFullscreen, loadingProgression } = useUnityContext(unityContexts.aiWeb);

  console.log(loadingProgression);

  return (
    <div className='step-container'>
      <h1>AI Algorithm Hacker</h1>
      <hr />
      { 
        isMobile 
        ? 
        <CusDialog 
          title='Device is not supported' 
          body='System detected that your current devices is not supported, some contexts may not be shown correctly.'
        /> 
        : null 
      }
      {
        loadingProgression === 1 
        ? null 
        : <h3 style={{ color: '#1976d2' }}>Loading Application... {Math.round(loadingProgression * 100)}%</h3>
      }
      { 
        loadingProgression === 1 
        ?
        <Stack spacing={2} direction="row">
          <Button
            variant='outlined' 
            startIcon={<FullscreenIcon />} 
            onClick={() => requestFullscreen(true)}
          >
            Fullscreen
          </Button>
          <Button
            variant='outlined' 
            startIcon={<DownloadIcon />} 
            onClick={() => window.open('https://drive.google.com/file/d/10VYGNd8AvA85qMhEms1A27xmfh4KBh3Q/view?usp=sharing', '_blank', 'noopener,noreferrer')}
          >
            Windows Build Download
          </Button>
        </Stack>
        : null 
      } 

      <div style={{ margin: '30px auto 300px auto' }}>
        <Unity unityProvider={unityProvider} style={{ width: 1280, height: 720 }} />
      </div>
    </div>
  )
}

const AIHackGame = () => {
  return(
    <div className='baseDiv'>
      <div className='bodyDiv'>
        <AIHackGameBody />  
        <HtmlFooter inOtherPage={true} />
      </div>
    </div>
  )
}

export default AIHackGame;