import { useState, useEffect, RefObject } from 'react';

const useOnScreen = (ref: RefObject<HTMLElement>, rootMargin: string = "0px"): boolean => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const currentElement = ref.current;

    if (currentElement) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIntersecting(entry.isIntersecting);
        },
        {
          rootMargin
        }
      );

      observer.observe(currentElement);

      return () => {
        if (currentElement) {
          observer.unobserve(currentElement);
        }
      };
    }

    return () => {};
  }, []);

  return isIntersecting;
};

export default useOnScreen;