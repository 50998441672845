import ReactPlayer from 'react-player';

type cusReactPlayerProps = {
  videolink?: string,
}

const CusReactPlayer = (props: cusReactPlayerProps) => {
  return (
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        url= {props.videolink}
        width='100%'
        height='400px'
        controls = {true}
      />
    </div>
  )
}

export default CusReactPlayer;
