import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import './../App.css';
import './IdeaPage.css';
import './../components/CodeBlock.css';
import './../components/CollapsibleView.css';

import Navbar from '../components/Navbar';
import CusScrollToTop from '../components/CusScrollToTop';
import HtmlFooter from '../components/HtmlFooter';
import BottomScrollProgressBarUI from '../components/BottomScrollProgressBar';
import { CollapsibleViewWeb } from '../components/CollapsibleView';
import CodeBlock, { codeBlockmode, codeBlocktheme } from '../components/CodeBlock';
import { web1DefaultCode, web2DefaultCode, web3DefaultCode } from '../constants/reactAceCodeText';
import { QuoteBlockWebIdeaPage } from '../components/QuoteBlock';
import SliderImage from '../components/SliderImage';

import example1 from '../assets/images/web/example1.png';
import img02 from '../assets/images/slide/img02.png'
import { mainPage, studyMaterialPage } from '../constants/pageName';

const WebIdeaPageBody = () => {
  useEffect(() => window.scrollTo(0,0), []);

	return (
    <div>
      <div className='step-container'>
        <h1>Things That You Need to Know</h1>
        <hr/>

        <div className='step-container-body'>
          <h2 style={{color: 'red'}}>
            Warning: This is not a tutorial on HTML, CSS, JavaScript or PHP.
            It only provides a general idea and shares my own experience on web development.
          </h2>

          <ul style={{listStyleType: 'none'}}>
            <li><b style={{fontSize: '30px'}}>Q</b>: Is it a must to know HTML, CSS, JavaScript and PHP in order to develope a website?</li>
            <li><b style={{fontSize: '30px'}}>A</b>: No, there are many website development softwares and tools with user-friendly UI and simple operations like drag and drop to help user without coding experience to create their own website.</li>
            <br/>
            <li><b style={{fontSize: '30px'}}>Q</b>: Are HTML and CSS hard to learn?</li>
            <li><b style={{fontSize: '30px'}}>A</b>: HTML and CSS are easy to learn and not require any programming skills.</li>
            <br/>
            <li><b style={{fontSize: '30px'}}>Q</b>: Are JavaScript and PHP hard to learn?</li>
            <li><b style={{fontSize: '30px'}}>A</b>: JavaScript and PHP require some programming skills, but it will be handy if you plan to add reactive element and function to your website.</li>
            <br/>
            <li><b style={{fontSize: '30px'}}>Q</b>: If I want to learn HTML, CSS, JavaScript and PHP, where should I start?</li>
            <li><b style={{fontSize: '30px'}}>A</b>: <a href='https://www.w3schools.com/' target='_blank' rel='noreferrer'>W3WSchool</a> will provide a free tutorial on HTML, CSS, JavaScript and PHP. Basically cover everything that you need to learn on web development.</li>
          </ul>
        </div>
      </div>

      <div className='step-container'>
        <h1>Basic Understanding</h1>
        <hr />
        <div className='step-container-body'>
          <p>Image the website as a human.</p>
          <table id='webtable'>
            <thead>
              <tr>
                <th>Tool</th>
                <th>Metaphor (Human)</th>
                <th>Website</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{width: '30%'}}>HTML</td>
                <td style={{width: '30%'}}>Body</td>
                <td style={{width: '40%'}}>Structure of the website</td>
              </tr>
              <tr>
                <td style={{width: '30%'}}>CSS</td>
                <td style={{width: '30%'}}>Clothes that you put on the Body</td>
                <td style={{width: '40%'}}>Decorate the appearance of the website</td>
              </tr>
              <tr>
                <td style={{width: '30%'}}>JavaScript</td>
                <td style={{width: '30%'}}>Reaction to the body under stimulates</td>
                <td style={{width: '40%'}}>Website: Click a button, Animation trigger event</td>
              </tr>
              <tr>
                <td style={{width: '30%'}}>PHP (or other server side languages i.e. NodeJS)</td>
                <td style={{width: '30%'}}>The skills of the human. Depends on his profession. For example if he is a fireman, he should be able to put out the fire.</td>
                <td style={{width: '40%'}}>If it is an online shopping website, it should contain the functions of check out, payment processing etc.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className='step-container'>
        <h1>Step 1: Prototyping</h1>
        <hr />
        <div className='step-container-body'>
          <div>
            Let's suppose you already equip the skills of web development,
            and start to create your first website.
          </div>
          <div>
            You probably would start with designing the layout of the website first.
            You can use different tools such as Photoshop to design your website's UI.
            <br/><br/>
            List the elements that you may want to include in the website and put them together using paper prototyping (or other prototyping tools).
            There are some general rules to follow according to Human Computer Interaction method (HCI).
            In general, the webpage should have a user-friendly UI. 
            A user-friendly UI should be consistent (style, color, use of language), easy reversal of actions, reduce short term memory load.
            <br/><br/>
            For effective web design, you may consider the navigation (how user can jump around your website) function of the webpage.
            Normally, user should be able to find the information they want within three clicks.
            Active tag should be used in navigation menu in order to let the user know which section they are viewing.
            <br/><br/>

            <figure>
              <img src={example1} style={{width: '95%', display: 'block', marginLeft: 'auto', marginRight: 'auto'}} alt=''/>
              <figcaption style={{textAlign: 'center', fontSize: '16px'}}>Example of UI prototyping of Banquet Management System using <a href='https://pencil.evolus.vn/' target='_blank' rel='noreferrer'>Pencil</a></figcaption>
            </figure>

            Depends on the nature (usability) of the website, the interaction element in the webpage should be balanced. 
            For example, a banking website (industrial use) should avoid unnecessary interaction element and decoration in order to increase the performance of the systems.
            <br/><br/>
            After finishing the UI prototyping, you need to decide which kinds of content should be placed in different section and list out the main idea for your own notes.
          </div>
        </div>
      </div>

      <div className='step-container'>
        <h1>Step 2: Get Ready to Start</h1>
        <hr />
        <div className='step-container-body'>
          <p style={{fontSize: '25px'}}>Things that you need: </p>
          <ul>
            <li>An Editor: Could be Notepad++ or Visual Studio Code or others (etc. dreamweaver)</li>
            <li>Your Brain</li>
          </ul>
          <p style={{fontSize: '25px'}}>
            Make it more structural and systematic...
          </p>
          <ul>
            <li>Main folder: WebProject</li>
            <li>/WebProject: </li>
            <ul>
              <li>1 css folder: all the style.css put here</li>
              <li>1 js folder: all the script.js put here</li>
              <li>1 php folder: all the php put here</li>
              <li>1 images folder: all the image files put here</li>
              <li>1...n xxx.html file</li>
            </ul>
          </ul>
          
          <p style={{fontSize: '25px'}}>File Tree (Click to expand)</p>
          <CollapsibleViewWeb />

          <p>For a relatively small project, you only need one .css, one .js and one .php file in the css, js, php folder respectively. </p>
          <p>
            Coding style: use different function() in .js and .php for different web functions and interactions, 
            so that you can call the function() in .html. 
            It will make the code easier to modify in the future.
          </p>
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.javascript}
              theme={codeBlocktheme.monokai}
              id={'web1'}
              value={web1DefaultCode}
              style={{ height: '350px' }}
            />
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.javascript}
              theme={codeBlocktheme.monokai}
              id={'web2'}
              value={web2DefaultCode}
              style={{ height: '350px' }}
            />
          </div>
        </div>
      </div>

      <div className='step-container'>
        <h1>Step 3: HTML - CSS - JavaScript - Server Side</h1>
        <hr />
        <div className='step-container-body'>
          <p>Now you can start to build your own website according to your prototype.</p>
          <p>Should always start with .html and .css first.</p>
          <p>
            For the handing div elements in html, you may need to add the border to the box element first,
            so that you will be able to locate different box elements and make it more easy to modify.
            You can delete the border after the adjustment.
          </p>
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.css}
              theme={codeBlocktheme.monokai}
              id={'web3'}
              value={web3DefaultCode}
              style={{ height: '150px' }}
            />
          </div>

          <p>
            After finishing the .html and .css, you can work on the .js and .php.
            If you need to implement database to your website, you can use the <a href='https://www.apachefriends.org/index.html' target='_blank' rel='noreferrer'>XAMPP</a> to create a localhost and test your PHP code.
            <br/><br/>
            Update 2023: well is 2023 now, and XAMPP is jsut too old. You may consider using <Link to={studyMaterialPage.dockerDeploy}>Docker</Link>.
          </p>
          <p>
            <strong style={{color: 'red'}}>Note: </strong> 
            PHP is a pretty old server-side language,
            even though many websites like Facebook still using PHP.
            For a relatively new server-side language,
            you may consider using <a href='https://nodejs.org/en/' target='_blank' rel='noreferrer'>Node.js</a>,
            which provides a higher performance over PHP.
            However, PHP is still the industry standard in web programming,
            it is easy to use and powerful.
            For now, I would say it is still the first server-side language that people should learn in server-side development.
          </p>
          <p>
            Hope this article can help you to have a overall view on web development.
          </p>
        </div>   
      </div>

      <div className='step-container'>
        <h1>My Created Websites</h1>
        <hr />
        <div className='step-container-body'>
          <div>
            <Link to={mainPage.webList}>
              <Button variant='outlined'>View My Created Webpages</Button>
            </Link>
          </div>
        </div>   
      </div>

      <div className='step-container'>
        <QuoteBlockWebIdeaPage />
      </div>
    </div>
	)
}

const WebIdeaPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='Get A Brief Idea On How to Create A Website'
          imgLink={img02}
        />

        <div className='content-container'>
          <WebIdeaPageBody />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default WebIdeaPage;