import $ from 'jquery';
import '../App.css';

////////// ////////// //////////
// 1. Pie Charts on Scroll Animation (deleted, use other package instead)
// 2. Horizontal Chart on Scroll Animation
////////// ////////// //////////
// The following code is originally from the excellent pen:
// https://codepen.io/StephenScaff/pen/VYaQGB by Stephen Scaff
export const horizontalChartsAnimation = () => {
  $(document).ready(function() {
    var $win = $(window), $win_height = $(window).height(), windowPercentage = $(window).height() * 0.9;
      
    $win.on("scroll", scrollReveal);
    function scrollReveal() {
      var scrolled = $win.scrollTop();
  
      // Horizontal Chart
      $(".chartBarsHorizontal .bar").each(function() {
        var $this = $(this), offsetTop = $this.offset().top;
          
        if (scrolled + windowPercentage > offsetTop || $win_height > offsetTop) { 
          $(this).each(function(key, bar) {
            var percentage = $(this).data("percentage");
      
            $(this).css("width", percentage + "%");
            // Animated numbers
            $(this).prop("Counter", 0).animate(
              {
                Counter: $(this).data("percentage")
              },
              {
                duration: 1500,
                easing: "swing",
                step: function(now) {
                  $(this).text(Math.ceil(now));
                }
              }
            );
          });
        } else {
          // To keep them triggered, lose this block.
          $(this).each(function(key, bar) {
            $(this).css("width", 0);
          });
        }
      });
    }
    scrollReveal();
  });
}


