import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import IndexPage from './pages/IndexPage';
import WorkTimelinePage from './pages/WorkTimelinePage';
import StudyMaterial from './pages/StudyMaterials/S3';
import GameIdeaPage from './pages/GameIdeaPage';
import WebIdeaPage from './pages/WebIdeaPage';
import ListOfArticlesPage from './pages/ListOfArticlesPage';
import ListOfPianoPage from './pages/ListOfPianoVideo';
import ListOfGamePage from './pages/ListOfGamePage';
import ListOfWebPage from './pages/ListOfWebPage';

import GameDesignComparativeStudyPage from './pages/Articles/GameDesignComparativeStudy';
import OnePhilosophyProblemPage from './pages/Articles/一個現實的哲學問題';
import HijackPage from './pages/Articles/劫機';
import NightReadingPage from './pages/Articles/夜讀有感';
import PlaceInHousePage from './pages/Articles/家中一隅';
import FreeAndLawPage from './pages/Articles/強調自由看似保障了所有人的權利，其實使人忽略紀律';
import FearPage from './pages/Articles/恐懼';
import ManInCornerPage from './pages/Articles/角落裡的人';
import FeedPage from './pages/Articles/飼料';

import S3BodyPage from './pages/StudyMaterials/S3';
import EC2BodyPage from './pages/StudyMaterials/EC2';
import DockerDeploy from './pages/StudyMaterials/DockerDeploy';
import BookmarkingServicePage from './pages/StudyMaterials/BookmarkingService';

import AIHackGame from './pages/GameList/AIHackGame';

import { mainPage, articlesPage, studyMaterialPage, gamesPage } from './constants/pageName';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<IndexPage />}/>
        <Route path={mainPage.timeline} element={<WorkTimelinePage/>}></Route>
        <Route path={mainPage.studyMaterial} element={<StudyMaterial/>}></Route>
        <Route path={mainPage.gameDev} element={<GameIdeaPage/>}></Route>
        <Route path={mainPage.webDev} element={<WebIdeaPage/>}></Route>
        <Route path={mainPage.myArticle} element={<ListOfArticlesPage/>}></Route>
        <Route path={mainPage.myPiano} element={<ListOfPianoPage/>}></Route>
        <Route path={mainPage.gameUnity} element={<ListOfGamePage/>}></Route>
        <Route path={mainPage.webList} element={<ListOfWebPage/>}></Route>

        <Route path={articlesPage.gameDesignStudy} element={<GameDesignComparativeStudyPage/>}></Route>
        <Route path={articlesPage.onePhilosophyProblem} element={<OnePhilosophyProblemPage/>}></Route>
        <Route path={articlesPage.hijack} element={<HijackPage/>}></Route>
        <Route path={articlesPage.nightReading} element={<NightReadingPage/>}></Route>
        <Route path={articlesPage.placeInHouse} element={<PlaceInHousePage/>}></Route>
        <Route path={articlesPage.freeAndLaw} element={<FreeAndLawPage/>}></Route>
        <Route path={articlesPage.fear} element={<FearPage/>}></Route>
        <Route path={articlesPage.manInCorner} element={<ManInCornerPage/>}></Route>
        <Route path={articlesPage.feed} element={<FeedPage/>}></Route>

        <Route path={studyMaterialPage.s3} element={<S3BodyPage/>}></Route>
        <Route path={studyMaterialPage.ec2} element={<EC2BodyPage/>}></Route>
        <Route path={studyMaterialPage.dockerDeploy} element={<DockerDeploy/>}></Route>
        <Route path={studyMaterialPage.bookmarkingService} element={<BookmarkingServicePage/>}></Route>

        <Route path={gamesPage.aiHackGame} element={<AIHackGame />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
