import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';

import './../App.css';
import './Navbar.css';
import CusDrawer from './CusDrawer';
import { mainPage } from '../constants/pageName';
import iconFacebook from '../assets/images/icon/iconFacebook.png';
import iconInstagram from '../assets/images/icon/iconInstagram.png';
import iconTwitter from '../assets/images/icon/iconTwitter.png';
import iconLinkedin from '../assets/images/icon/iconLinkedin.png';
import iconGithub from '../assets/images/icon/iconGithub.png';

type navbarProps = {
  inAboutMeArea: boolean,
  inSkillArea: boolean,
  inHobbyArea: boolean,
  inScheduleArea: boolean,
  inContactArea: boolean,
  inOtherPage: boolean,
}

const activeBtn: CSSProperties = {
  backgroundColor: '#333',
  color: '#fff',
};

const Navbar = (props: navbarProps) => {
  const navigate = useNavigate();

  return (
    <nav className='navbar sticky-top navbar-expand-lg navbar-light bg-light' style={{ top: '1%' }}>
      <CusDrawer />
      <div className='d-flex flex-grow-1'>
        <span className='w-100 d-lg-none d-block'></span>
        <a className='navbar-brand' href={props.inOtherPage ? '/' : '#'}>
          Jason Lai | Home
        </a>
        <div className='w-100 text-right'>
          <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#myStickyNavbar'>
            <span className='navbar-toggler-icon'></span>
          </button>
        </div>
      </div>
      <div className='collapse navbar-collapse flex-grow-1 text-right' id='myStickyNavbar'>
        <ul className='navbar-nav ml-auto flex-nowrap'>
          <li className='nav-item'>
            <a className='nav-link' href={props.inOtherPage ? '/' : '#sectionAboutMe'} style={props.inAboutMeArea ? activeBtn : {}}>About</a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href={props.inOtherPage ? '/' : '#sectionSkill'} style={props.inSkillArea ? activeBtn : {}}>Skill</a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href={props.inOtherPage ? '/' : '#sectionHobby'} style={props.inHobbyArea ? activeBtn : {}}>Hobby</a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href={props.inOtherPage ? '/' : '#sectionSchedule'} style={props.inScheduleArea ? activeBtn : {}}>Schedule</a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href={props.inOtherPage ? '/' : '#sectionContact'} style={props.inContactArea ? activeBtn : {}}>Contact</a>
          </li>
          <li className='nav-item dropdown'>
            <a className='nav-link dropdown-toggle' href='#.html' id='navbarDropdown' role='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
              Page
            </a>
            <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
              <button className='dropdown-item' onClick={() => navigate(mainPage.timeline, {replace: true})}>Working Experience</button>
              <button className='dropdown-item' onClick={() => navigate(mainPage.studyMaterial, {replace: true})}>Study Materials</button>
              <div className='dropdown-divider'></div>
              <button className='dropdown-item' onClick={() => navigate(mainPage.webDev, {replace: true})}>Web Development</button>
              <button className='dropdown-item' onClick={() => navigate(mainPage.gameDev, {replace: true})}>Game Development</button>
              <div className='dropdown-divider'></div>
              <button className='dropdown-item' onClick={() => navigate(mainPage.webList, {replace: true})}>My Webpages</button>
              <button className='dropdown-item' onClick={() => navigate(mainPage.gameUnity, {replace: true})}>My Games</button>
              <button className='dropdown-item' onClick={() => navigate(mainPage.myArticle, {replace: true})}>My Articles</button>
              <button className='dropdown-item' onClick={() => navigate(mainPage.myPiano, {replace: true})}>My Piano Video</button>
            </div>
          </li>

          <li className='nav-item'>
            <a target='_blank' rel='noopener noreferrer' href='https://www.facebook.com/'><img src={iconFacebook} className='subsIcon' alt=''/></a>
          </li>
          <li className='nav-item'>
            <a target='_blank' rel='noopener noreferrer' href='https://www.instagram.com/'><img src={iconInstagram} className='subsIcon' alt=''/></a>
          </li>
          <li className='nav-item'>
            <a target='_blank' rel='noopener noreferrer' href='https://twitter.com/'><img src={iconTwitter} className='subsIcon' alt=''/></a>
          </li>
          <li className='nav-item'>
            <a target='_blank' rel='noopener noreferrer' href='https://www.linkedin.com/in/jason-lai-339708152'><img src={iconLinkedin} className='subsIcon' alt=''/></a>
          </li>
          <li className='nav-item'>
            <a target='_blank' rel='noopener noreferrer' href='https://github.com/JasonLai97'><img src={iconGithub} className='subsIcon' alt=''/></a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar;