import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { IconButton, Typography } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import { studyMaterialPage } from '../constants/pageName';

const drawerItemsAWS = [
  'S3',
  'EC2'
];
const drawerItemsAWSLink = [
  studyMaterialPage.s3,
  studyMaterialPage.ec2
];

const drawerItemsAPI = [
  'Bookmarking DB Example'
];
const drawerItemsAPILink = [
  studyMaterialPage.bookmarkingService
];

const drawerItemsTools = [
  'Docker'
];
const drawerItemsToolsLink = [
  studyMaterialPage.dockerDeploy
];

type cusDrawerProps = {
  defaultOpen?: boolean
}

const CusDrawer = (props: cusDrawerProps) => {
  const navigate = useNavigate();
  const [state, setState] = React.useState(props.defaultOpen || false);
  const toggleDrawer = () => {setState(!state);};

  const list = () => (
    <Box
      onClick={() => toggleDrawer()}
      onKeyDown={() => toggleDrawer()}
    >
      <Typography fontSize={26} padding={2}>Study Materials</Typography>
      <List>
        <ListItem>
          <Typography fontSize={20}>AWS</Typography>
        </ListItem>
        {drawerItemsAWS.map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => navigate(drawerItemsAWSLink[index], {replace: true})}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}

        <Divider />

        <ListItem>
          <Typography fontSize={20}>Algorithms</Typography>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => window.open(studyMaterialPage.sorting, '_blank')}>
            <ListItemText primary='Sorting Algorithm' />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem>
          <Typography fontSize={20}>Dev Tools</Typography>
        </ListItem>
        {drawerItemsTools.map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => navigate(drawerItemsToolsLink[index], {replace: true})}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}

        <Divider />

        <ListItem>
          <Typography fontSize={20}>RESTful API</Typography>
        </ListItem>
        {drawerItemsAPI.map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => navigate(drawerItemsAPILink[index], {replace: true})}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton  size='large' aria-label='delete'  onClick={() => toggleDrawer()}>
        <MenuIcon/>
      </IconButton >
      <Drawer open={state} onClose={() => toggleDrawer()}>
        {list()}
      </Drawer>
    </div>
  );
}

export default CusDrawer;