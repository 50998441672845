import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from "react";

import './../App.css';
import './IdeaPage.css';

import Navbar from '../components/Navbar';
import CusScrollToTop from '../components/CusScrollToTop';
import HtmlFooter from '../components/HtmlFooter';
import BottomScrollProgressBarUI from '../components/BottomScrollProgressBar';
import CusCard from '../components/CusCard';
import { myWebpages } from '../constants/pageName';

import sortingAlgorithm from '../assets/images/webList/sortingAlgorithm.png';
import onlineShopping from '../assets/images/webList/onlineShopping.png';


const ListOfWebPageBody = () => {
  useEffect(() => window.scrollTo(0,0), []);

  return (
    <div className='step-container'>
      <h1>Visit My Other Webpages</h1>
      <hr />
      <p>
        Here are some of my websites created using HTML, CSS, JS, PHP, SQL, Ajax, Google API chart etc.. The websites are currently hosting on AWS EC2 server:
      </p>
      <CusCard 
        imgUrl={sortingAlgorithm} 
        webUrl={myWebpages.sortingAlgorithms}
        title='Sorting Algorithm'
        intro='The website includes step by step animation explanation on six sorting algorithms, as well as different online exercises. Moreover, the website includes the File System that support CRUD operation with txt files. User can also share and publish their file to the website. Comment Section, real-time Chat Room and Leaderboard are also included on the website. Only the Admin account can view other users.'
        webUserName='Admin'
        webPW='admin'
      />
      <CusCard 
        imgUrl={onlineShopping} 
        webUrl={myWebpages.onlineShopping}
        title='Dreamland'
        intro='The website includes the functions of Account Management, Inventory Management and Purchasing Process. User can also upload the products to the server and other customer can view and buy it online.'
        webUserName='User'
        webPW='user'
      />
    </div>
  )
}

const ListOfWebPage = () => {
  return(
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <div className='content-container' style={{ minHeight: '1500px' }}>
          <ListOfWebPageBody />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default ListOfWebPage;