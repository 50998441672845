import './../App.css';
import './SectionLine.css'

const SectionLine = () => {
  return (
    <>
      <br/><br/>
      <hr id='sectionLine'></hr>
      <br/><br/>
    </>
  )
}

export default SectionLine;