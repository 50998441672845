import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './../../App.css';
import './../ListOfPage.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import SliderImage from '../../components/SliderImage';
import { mainPage } from '../../constants/pageName';
import MainBtn from '../../components/MainBtn';

import img04 from './../../assets/images/slide/img04.png';

const PlaceInHouse = () => { 
  useEffect(() => window.scrollTo(0,0), []);

  const navigate = useNavigate();
  const navigateToArticlesList = () => { navigate(mainPage.myArticle); };

	return (
    <div>
      <div id="step-container">
        <div id="articles-info">
          <h1>《家中一隅》</h1>
          <h3>2012年9月14日</h3>
        </div>

        <div id="step-container-body">
          <p className="first">
            這個地方，曾經陪伴我度過無數個失眠的夜晚，亦是在考試前幾個晚上，陪伴我一起埋頭苦讀的地方。在我心目中，它是神聖而不可侵犯的，那就是我的房間。
          </p>
          <p className="first">
            我的房間不算大，也不算小。在床的旁邊擺放了一個衣櫃，床頭放了一張書桌。為節省空間，當初搬進來時專程找了這張書桌，因為它和書櫃連在一起，既方便做功課，又能在書櫃放我的教科書和小說，中間還有一層，剛好夠放一套小型音響，在失眠時播放音樂，讓我的心靈得以平靜。書桌上放了一缸金魚，這一尾金魚養了接近三年了，在我印象中，牠除了吃就是睡，倒是活得消遙自在。
          </p>
          <p className="first">
            牆上貼了一張海報，上面有三個壯漢拿著機關槍在掃射，每當我看到這張海報，我就能提起精神做功課，我也不知是什麼原因，而且在睡房貼這張東西還真是怪怪的。從房間的窗外望去，迎面就是一棟棟的高樓大廈，給人一種無形的壓迫感，於是我近日買了一株小植物放在陽台上，給這個死寂的畫面增添一些生氣。床尾的牆上有個架子，上面擺放著一個鐘，還有幾件藝術品。
          </p>
          <p className="first">
            這就是我的房間，我的家中一隅。
          </p>
        </div>

        <br/><br/>

        <div className = 'buttonWrapper'>
          <MainBtn text='Back' onClick={() => navigateToArticlesList()}/>
        </div>
      </div>
    </div>
	)
}

const PlaceInHousePage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='這個地方，曾經陪伴我度過無數個失眠的夜晚...'
          imgLink={img04}
        />

        <div className='content-container'>
          <PlaceInHouse />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default PlaceInHousePage;