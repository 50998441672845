import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './../../App.css';
import './../ListOfPage.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import SliderImage from '../../components/SliderImage';
import { mainPage } from '../../constants/pageName';
import MainBtn from '../../components/MainBtn';

import img04 from './../../assets/images/slide/img04.png';

const GameDesignComparativeStudy = () => { 
  useEffect(() => window.scrollTo(0,0), []);

  const navigate = useNavigate();
  const navigateToArticlesList = () => { navigate(mainPage.myArticle); };

	return (
    <div>
      <div id="step-container">
        <div id="articles-info">
          <h1>《Game Design Comparative Study》</h1>
          <h3>30/11/2018</h3>
        </div>

        <div id="step-container-body">
          <h3><u>Introduction:</u></h3>
          <p className="first">
            In this comparative study, I would like to compare two popular computer-based games based on the game design principle. The two games are:
            <ol>
              <li><b><i><u>Witcher 3</u></i></b> : <b>Witcher series</b> from <b>CD Projekt RED</b> (since 2008)</li>
              <li><b><i><u>Fallout 4</u></i></b> : <b>Fallout series</b> from <b>Bethesda Softworks</b> (since 2004)</li>
            </ol>
            Two games are both open world role-playing games (RPG). For the first game, Witcher 3 is the final game in the Witcher trilogy. CD Projekt RED released The Witcher in 2007. The Witcher is based on the novel series of The Witcher by Polish author Andrzej Sapkowski. For the second game, Fallout is a series of post-apocalyptic action role-playing video game, and Fallout 4 is one of the most successful game in the series.
          </p>
          <h3><u>General Background of 2 Games:</u></h3>
          <b>Witcher 3</b>:
          <p className="first">
            Witcher series are the most popular Western Style ARPG in the game industry. The reputation of Witcher are far more than almost any similar kinds of the games, since in 2015, Poland’s Prime Minister gave a collector’s edition of “The Witcher 2: Assassins of Kings” to the former US president Obama to commemorate his visit. It is the first time, computer game takes place in the stage of political. In this game, the player plays as the legendary monster hunter Geralt of Rivia to kill different kinds of monsters such as dryads, werewolves and vampires mainly. 
          </p>
          <p className="first">
            The first game of Witcher (2007) is not very famous, the fighting mechanism and the animation are very stiff compare to other kinds of ARPG (such as Mass Effect from EA) at that time. However, it receives a general positive feedback from the mainstream media since it contains most of the elements of an RPG and the storyline is quite impressive. It allows CD Projekt RED to develop the second game: The Witcher 2: Assassins of Kings (2011). The second game improves a lot in graphic, animation and fighting mechanism. Player can do more things like counter strike in combat. Moreover, the storyline and game world are much more epic and bigger than the previous game. Therefore, it successfully attracts more players and even become the critical and commercial success for the whole series. By May 2012, the game has been sold more than 2 million copies. It received extremely positive feedback from the mainstream media, including 8.5/10 of IGN review and IGN editors’ choice, best of E3, extremely positive feedback in Steam, 5 stars of GamePro Score and 10/10 of RPG produce.
          </p>
          <p className="first">
              The success of Witcher 2 allows CD Projekt RED to put much more resource or even a massive investment in the development of Witcher 3. The Witcher 3 has big improvement in almost every aspect, such as a more realistic open world with second generation of REDengine 3, more decisions that would lead to different consequences, and much longer gaming time (from 35 hours in Witcher 2 to almost 150 hours in Witcher 3). The Witcher 3 becomes the most successful game among the whole series. It receives almost prefect feedback from the mainstream media and winner of over 800 awards including The Game Awards 2015: Game of the Year, Best RPG and Developer of the Year, Best Storytelling, Best Visual Design, 9.3/10 of IGN review and IGN editors’ choice, best of E3, overwhelming praise feedback in Steam. By 2018, the Witcher series sold over 33 million copies world widely. It becomes one of the most remarkable RPG in the industry and a milestone in RPG history. 
          </p>
          <b>Fallout 4: </b>
          <p className="first">
            Fallout is another award-winning series in the RPG industry. However, the develop team has a lot of change in the whole series. At the very early stage Fallout (1997) and Fallout 2 (1998) were developed by Black Isle Studio. Fallout 1 and 2 are 45 degree 2.5d pure role play game. By considering the game engine at that time, the graphic of the game was very stunning. The storyline and the gameplay also be praised with positive review. According to the mainstream media, Fallout got the awards including RPG of the Year (1997) from GameSpot, Role-Playing Game of the Year (1998) from Computer Gaming World and Role-Playing Game of the Year from Computer Game Strategy Plus. PC Gamer (US) give the score of 90/100 to Fallout. Fallout 2 had even more reputation than the previous game, as a 1998s game, the mainstream media still rate this game as a textbook example for the other RPG. In 2013, GamesRadar ranked Fallout 2 number 68 on their list of top video games of all time, IGN ranked it as the 28th best role-playing video game ever. In 2015, PC Gamer ranked the game number 3 on its list of best RPGs of all time.
          </p>
          <p className="first">
            After almost 10 years later, in 2008, Fallout 3 was released by Bethesda Softworks, which is the developer of another award-winning RPG “The Elder Scrolls Skyrim”. Fallout 4 was released in 2015 with the same developer. The genre of Fallout has changed from RPG to ARPG (A = action) after the change of developer. It became a third person 3D game. The player plays as a survivor after the nuclear war. The player can interact with NPC (Nor-Player Character) in the game World, and they often need to shoot and kill the other survivors and the mutant creatures. Both games have improved a lot in many aspects since it is a decade of technology advancement since 1998. Both games also receive a lot of awards like Best Role-Playing Game of E3, Game of The Year etc. Fallout 3 and 4 also have successful sales in the market, Fallout 3 had sold around 12.4 million copies worldwide while Fallout 4 had sold more copies then Skyrim which is around 30 million copies by 2016.
          </p>
          <h3><u>Psychology of Computer Gameplayers:</u></h3>
          <b><i>Player Groups and Their Characteristics:</i></b>
          <p className="first">
            Both games are ARPG, therefore the player groups of both games share similar characteristics. Two games are also very similar in different aspect. For example, both games are killing monster and mutants; both games are open world which enable player to explore the game world freely; both games emphasize on the storyline and decision making etc. ARPG are very gender neutral. No job background is needed in both games. However, both games contain rich literary contents especially for Witcher, it requires a mature understanding in both literature and life experience to understand some plot as well as the idea that the games want to carry out. It also contains many violence and sexual contents. Therefore, both games are more suitable for mature player or teen adult.
          </p>
          <p className="first">
            For some players that do not have any experience in RPG or ARPG types of game, and like some pure online action game or FPS (first-person-shooting) like CS (Counter-Strike), Killing Floor, DoA (Dead or Alive), L4D2 (Left 4 Dead 2) or PUBG (Player unknown’s Battleground) etc., they may find it boring since the pitch of RPG type of games are relatively slow and often have a more complicated background story that require the player to understand.
          </p>
          <b><i>Reinforcement Principle:</i></b>
          <p className="first">
            Both games satisfy the lowest level of the psychological needs of game player, which is the Physical Experience of game player. In the two games, player can explore the game world freely and start their own adventure in different location of the game world. Different location has different types of enemies and different challenges. Rewards will be given to the player and the quality of rewards depends on the difficulties of enemies and quest.
          </p>
          <p className="first">
            Both games satisfy the second level of the psychological needs of game player, which is the Identity of game player. There are some pros and cons for two games in this aspect, but in general both games have similar rank in this level. Every decision that have been made in Witcher 3 and Fallout 4 will leave permanent consequence and change the game world. For example, both games have an NPC Favorability System, if the player do something that against the wish of NPC, their favorability to player will drop and act ruder to the player. For Witcher 3, if the player helps the villagers to solve the problem, the villagers will always welcome the player when he/she revisit the village again. Both game emphasis a lot on the identity of the player, and likes any other RPG, identity of the player is always a high priority in design stage. Fallout 4 have the character face binding system that allows user to create his/her own characters. Wither 3 have more decision making and consequences (44 different ending in Witcher 3 compare to 4 different ending in Fallout 4). Therefore, both games have similar rank in the level of Identity. 
          </p>
          <p className="first">
            Both games satisfy the third level of the psychological needs of game player, which is the Esteem of game player. The Fallout 4 has done better in this aspect, since it has a bigger skill sets and the skills tree are much bigger than that in Witcher 3.
          </p>
          <p className="first">
            Both games satisfy the fourth level of the psychological needs of game player, which is the Belonging of game player. Both games contain over ten thousand lines of dialogue between the player and NPC, the communication and interaction between player and NPC are frequently happens. In Witcher 3, the game player often needs to accomplish a quest with the NPC while in fallout 4 the player can ask the NPC to follow you when starting a mission.
          </p>
          <b><i>Cognitive Dissonance Principle:</i></b>
          <p className="first">
            Two games basically are designed consistent with our beliefs. In Witcher 3 the player is not allowed to kill the innocents. In Fallout 4, if the player attacks the ordinary NPC (not enemy or quest target), the whole NPC in that area will turn against the player and the player need to wait a very long time to revisit the same area to accomplish the quest that used to be.
          </p>
          <p className="first">
            There are some cognitive dissonance principle can be found in both games. Since both games allow player to make decision and leave permanent consequence, some consequences maybe unexpected or unwanted to the player, and this occurs more often in Witcher 3 since sometime only 1 decision would lead to a good ending in 3 choices, but most of choices in Fallout 4 would lead to a good ending. In order to diminish the dissonance, the player may convince himself/herself that the their choice is correct and tends to do the same decision when facing the similar situations, therefore some players will have a very bad ending at the end of the game.
          </p>
          <b><i>Theory of Regret:</i></b>
          <p className="first">
            The theory of Regret is done better in Witcher 3 compare to Fallout 4. Witcher 3 emphasis more in reality, and most of the decision in the game would lead to a very bad result. The player would not even notice the bad consequence at the instant that the decision is made, but the consequence will appear as the story moving on. If the player wants to get back to the special quest to correct the decision, they need to save and load constantly, and if the player forget to save the important quest, the only way for correction is to start the new game. It greatly increases the gaming time and allow player to continuous playing to discover different endings and different choices.
          </p>
          <p className="first">
            The same will happen in Fallout 4, however, most of the decision made in Fallout 4 will lead to good ending. No matter how the player chose, the ending will make the player become a hero in the game world.
          </p>
          <b><i>Cognitive System’s limitation:</i></b>
          <p className="first">
            Since both games are RPG, the player need time to remember the effect of different items and how to use different equipments to have the maximum enhancement to the character. The player needs to understand the interference among game knowledge. For Fallout 4, it requires more cognitions since it have a building system that allows player to build different things in the game world, the control and key build are relatively complicated compare to Witcher 3. Both games use keyboard and mouse as input devices, the muscle movement required is similar for two games.
          </p>
          <h3><u>Social and Cultural Characteristics:</u></h3>
          <p className="first">
            Both games are western style RPG, compare to the Japanese style RPG, the western style RPG are not emphasis on gorgeous actions, perfection in characters and pure fantasy. Western style RPG emphasis more on reality and the freedom of the player. Not only for Witcher and Fallout, but also other production likes Mass Effect, Skyrim, Assassin’s Creed etc., most of these games are based on science, things that can be potentially happens and history, which are totally different with Final Fantasy, Monster Hunter etc.
          </p>
          <p className="first">
            For Witcher 3 and Fallout 4, both games include a lot of adult contents. The blood and violent cutscenes are very detail in both games. The sexual contents and nudity occur constantly in Witcher 3 compare to Fallout 4. The drug use contents occur constantly in Fallout 4. Both games contain the western style of dark humor and ironic reflection on modern society. 
          </p>
          <h3><u>Design of Human Computer Interaction:</u></h3>
          <p className="first">
            Two games use the UI design that can reduce memorization of player. There is a tutorial section in the stop menu of Witcher 3. For Fallout 4, the “pip boy” (the device in game) contains every necessary information that the player may need to know. For Witcher 3, the player can lock camera on the enemy during combat. Fallout 4 does not have this function, but it allows user to change from third person to first person, which is the feature that does not exist in Witcher 3.
          </p>
          <h3><u>Conclusion:</u></h3>
          <p className="first">
            In conclusion, the study compares two similar ARPG (Witcher 3 and Fallout 4) in different perspectives include: Psychology of Computer Gameplayers, Social and Cultural Characteristics and Design of Human Computer Interaction. Both games have its own feature and advantage in different area. In general, Witcher 3 and Fallout 4 are both amazing and outstanding ARPG in the industry.
          </p>
        </div>

        <br/><br/>

        <div className = 'buttonWrapper'>
          <MainBtn text='Back' onClick={() => navigateToArticlesList()}/>
        </div>
      </div>
    </div>
	)
}

const GameDesignComparativeStudyPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='Witcher 3 VS Fallout 4'
          imgLink={img04}
        />

        <div className='content-container'>
          <GameDesignComparativeStudy />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default GameDesignComparativeStudyPage;