import $ from 'jquery';
import './BottomScrollProgressBar.css';

////////// ////////// //////////
// JS for bottom scroll progress bar
////////// ////////// //////////
const bottomScrollProgressBar = () => {
  $(window).scroll(function () {
    var scrollPercent;
    var s = $(window).scrollTop(),
        d = $(document).height(),
        c = $(window).height();
    
    scrollPercent = (s / (d-c)) * 100;
    var position = scrollPercent;
  
    $("#progressbar").attr('value', position);
  });
}

const BottomScrollProgressBarUI = () => {
  bottomScrollProgressBar();
  return (
    <progress id='progressbar' value='0' max='100'></progress>
  )
}

export default BottomScrollProgressBarUI;