import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';

import './../../App.css';
import './../IdeaPage.css';
import './studyPage.css';
import './../../components/CodeBlock.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import CusSnackeBar from '../../components/CusSnackeBar';

import s3Img1 from '../../assets/images/study/S3_1.png';
import s3Img2 from '../../assets/images/study/S3_2.png';
import s3Img3 from '../../assets/images/study/S3_3.png';
import s3Img4 from '../../assets/images/study/S3_4.png';

const S3Body = () => {
  useEffect(() => window.scrollTo(0,0), []);

	return (
    <div className='step-container'>
      <h1>Host Your Static Website Using AWS S3</h1>
      <hr/>
      <div className='step-container-body'>
        <div id='study-contents'>
          <h2>Edit: 03-01-2023</h2>
          Please noted the date here since different versions may not be operated in same way.

          <h2>Pre-Requirements</h2>
          <ul>
            <li>Amazon Web Services Account</li>
          </ul>

          <h2>Modules Will Be Used</h2>
          <ul>
            <li>S3</li>
            <li>CloudFont (Optional: for domain name)</li>
            <li>Route 53 (Optional: for domain name)</li>
            <li>Certificate Manager (Optional: for domain name)</li>
          </ul>

          <h2>Step 1: Upload the web project files to S3</h2>
          <div>
            First, create a new S3 bucket. Just enter the bucket name you want. For example <b><i>new-s3-https-test</i></b>.
            <img src={s3Img1} className='imageBox' alt=''/>

            Set the permission to public.
            <img src={s3Img2} className='imageBox' alt=''/>

            Now upload the web project file to S3. Here I use the helloWorld.html:
            <img src={s3Img3} className='imageBox' alt=''/>

            Click into the html file and click on the Properties tag you will see the link below:
            <img src={s3Img4} className='imageBox' alt=''/>

            <p>
              You can use the link to access your website. 
              Congratulations, now you can host your static website using S3.
            </p>

            <p style={{ fontStyle: 'italic', color: 'red' }}>
              # The S3 has built in support for static website hosting using HTTPS protocol.
              If you want to use the domain name for your website or if you want to host React build projects,
              you will need to have a domain and SSL certificate.
              The <b>Route 53</b> section (Coming Soon) will show you how to register a domain in <b>Route 53</b> and get a SSL certificate using the <b>Certificate Manager</b>.
            </p>
          </div>
        </div>
      </div>   
    </div>
	)
}

const S3BodyPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <CusSnackeBar messageText='Click on top left icon to open side menu' defaultOpen={true}/>

        <div className='content-container'>
          <S3Body />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default S3BodyPage;