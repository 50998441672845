import './../App.css';
import './QuoteBlock.css';

export const QuoteBlockIndexPage = () => {
  return (
    <div id='pquote'>
      <div style={{lineHeight: '2.8', fontSize: '1.5vw', fontStyle: 'italic'}}>
        <strong style={{fontSize: '4vw', color: 'rgb(129, 127, 255)'}}>&#x275D;</strong>

        Some people choose to see the 
        <strong style={{fontSize: '2vw', color: 'rgba(223, 61, 61, 0.836)'}}> ugliness</strong> in this world.
        The disarray. 
        I choose to see the <strong style={{fontSize: '2.5vw', color: 'rgb(93, 186, 214)'}}>beauty</strong>.
        To believe there is an <strong style={{fontSize: '2.2vw', color: 'rgb(188, 219, 73)'}}>order</strong> to our days, 
        a <strong style={{fontSize: '2.3vw', color: 'rgba(86, 214, 107, 0.836)'}}>purpose</strong>. 

        <strong style={{fontSize: '4vw', color: 'rgb(129, 127, 255)'}}>&#x275E;</strong>
        <br/>
        <p style={{float: 'right'}}>&#x268A; Westworld: Dolores</p>
      </div>
    </div>
  )
}

export const QuoteBlockTimelinePage = () => {
  return (
    <div id='pquote'>
      <div style={{lineHeight: '2.8', fontSize: '1.5vw', fontStyle: 'italic'}}>
        <strong style={{fontSize: '4vw', color: 'rgb(129, 127, 255)'}}>&#x275D;</strong>

        <strong style={{fontSize: '2.3vw', color: 'rgba(223, 61, 61, 0.836)'}}>Evil </strong>
        is Evil. 
        <strong style={{fontSize: '2vw', color: 'rgba(94, 223, 61, 0.836)'}}> Lesser</strong>,
        <strong style={{fontSize: '2.6vw', color: 'rgb(149, 93, 214)'}}> greater</strong>,
        middling...Makes
        <strong style={{fontSize: '2.2vw', color: 'rgb(188, 219, 73)'}}> no difference</strong>.
        The degree is
        <strong style={{fontSize: '2.2vw', color: 'rgb(219, 73, 141)'}}> arbitrary</strong>. 
        The definition's
        <strong style={{fontSize: '2.2vw', color: 'rgb(141, 140, 69)'}}> blurred</strong>. 
        If I'm to choose between one evil and another... I'd rather
        <strong style={{fontSize: '2.8vw', color: 'rgb(52, 176, 180)'}}> not choose </strong>
        at all. 

        <strong style={{fontSize: '4vw', color: 'rgb(129, 127, 255)'}}>&#x275E;</strong>
        <br/>
        <p style={{float: 'right'}}>&#x268A; Andrzej Sapkowski, The Last Wish</p>
      </div>
    </div>
  )
}

export const QuoteBlockWebIdeaPage = () => {
  return (
    <div id='pquote'>
      <div style={{lineHeight: '2.8', fontSize: '1.5vw', fontStyle: 'italic'}}>
        <strong style={{fontSize: '4vw', color: 'rgb(129, 127, 255)'}}>&#x275D;</strong>

        There is nothing like a 
        <strong style={{fontSize: '2vw', color: 'rgba(223, 61, 61, 0.836)'}}> higher purpose </strong>
        to 
        <strong style={{fontSize: '2.5vw', color: 'rgb(93, 186, 214)'}}> permit</strong>,
        men to do the
        <strong style={{fontSize: '2.2vw', color: 'rgb(188, 219, 73)'}}> unspeakable</strong>.

        <strong style={{fontSize: '4vw', color: 'rgb(129, 127, 255)'}}>&#x275E;</strong>
        <br/>
        <p style={{float: 'right'}}>&#x268A; The Witcher: Tissaia</p>
      </div>
    </div>
  )
}

export const QuoteBlockGameIdeaPage = () => {
  return (
    <div id='pquote'>
      <div style={{lineHeight: '2.8', fontSize: '1.5vw', fontStyle: 'italic'}}>
        <strong style={{fontSize: '4vw', color: 'rgb(129, 127, 255)'}}>&#x275D;</strong>

        A 
        <strong style={{fontSize: '2vw', color: 'rgba(223, 61, 61, 0.836)'}}> lion </strong>
        doesn't 
        <strong style={{fontSize: '2.5vw', color: 'rgb(93, 186, 214)'}}> concern</strong>,
        himself with the opinions of a
        <strong style={{fontSize: '2.2vw', color: 'rgb(188, 219, 73)'}}> sheep</strong>.

        <strong style={{fontSize: '4vw', color: 'rgb(129, 127, 255)'}}>&#x275E;</strong>
        <br/>
        <p style={{float: 'right'}}>&#x268A; GOT: Tywin Lannister</p>
      </div>
    </div>
  )
}

export const QuoteBlockListOfArticlesPage = () => {
  return (
    <div id='pquote'>
      <div style={{lineHeight: '2.8', fontSize: '1.5vw', fontStyle: 'italic'}}>
        <strong style={{fontSize: '4vw', color: 'rgb(129, 127, 255)'}}>&#x275D;</strong>

        The 
        <strong style={{fontSize: '2vw', color: 'rgba(223, 61, 61, 0.836)'}}> truth </strong>
        doesn't care about our needs or wants &#x268A; it doesn't care about our governments, our
        <strong style={{fontSize: '2.5vw', color: 'rgb(93, 186, 214)'}}> ideologies</strong>,
        our religions &#x268A; to lie in wait for all time. This, at last, is the gift of Chernobyl. Where I once would fear the
        <strong style={{fontSize: '2.2vw', color: 'rgb(188, 219, 73)'}}> cost of truth</strong>,
        now I only ask what is the 
        <strong style={{fontSize: '2.3vw', color: 'rgb(188, 219, 73)'}}> cost of lies</strong>.

        <strong style={{fontSize: '4vw', color: 'rgba(129, 127, 255)'}}>&#x275E;</strong>
        <br/>
        <p style={{float: 'right'}}>&#x268A; Chernobyl</p>
      </div>
    </div>
  )
}

export const QuoteBlockListOfPianoVideoPage = () => {
  return (
    <div id='pquote'>
      <div style={{lineHeight: '2.8', fontSize: '1.5vw', fontStyle: 'italic'}}>
        <strong style={{fontSize: '4vw', color: 'rgb(129, 127, 255)'}}>&#x275D;</strong>

        <strong style={{fontSize: '2vw', color: 'rgba(223, 61, 61, 0.836)'}}> Music </strong>
        gives a
        <strong style={{fontSize: '2.5vw', color: 'rgb(93, 186, 214)'}}> soul to the universe</strong>,
        <strong style={{fontSize: '2.2vw', color: 'rgb(188, 219, 73)'}}> wings to the mind</strong>,
        <strong style={{fontSize: '2.3vw', color: 'rgb(219, 73, 141)'}}> flight to the imagination</strong>,
        and 
        <strong style={{fontSize: '2.8vw', color: 'rgb(52, 176, 180)'}}> life to everything</strong>.
        <strong style={{fontSize: '4vw', color: 'rgba(129, 127, 255)'}}>&#x275E;</strong>
        <br/>
        <p style={{float: 'right'}}>&#x268A; Plato</p>
      </div>
    </div>
  )
}