import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect, useState } from 'react';

import './../App.css';
import './WorkTimelinePage.css';

import Navbar from '../components/Navbar';
import CusScrollToTop from '../components/CusScrollToTop';
import HtmlFooter from '../components/HtmlFooter';
import BottomScrollProgressBarUI from '../components/BottomScrollProgressBar';
import { QuoteBlockTimelinePage } from '../components/QuoteBlock';
import ImageViewerDialog from '../components/ImageViewerDialog';
import oracleRef from '../assets/images/oracleRef.png';

const WorkTimelinePageBody = () => { 
  useEffect(() => window.scrollTo(0,0), []);

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const imageOracleRefLetter = {
    img: oracleRef,
    title: 'Oracle Reference Letter'
  };

	return (
    <div>
      <div id='step-container'>
        <br/>
        <h1 style={{marginLeft: '15%'}}>Timeline</h1>

        <div id='step-container-body'>
          { /* Timeline */ }
          <ul className='timeline'>
            <li className='timeline-event'>
              <label className='timeline-event-icon'></label>
              <div className='timeline-event-copy'>
                <p className='timeline-event-thumbnail'>09/2016</p>
                <h3>The Hong Kong Polytechnic University</h3>
                <h4>Computing (Computer Science)</h4>
                <p>
                  <strong>Year 1</strong><br/>
                  Broad Discipline of Computing: fundamental computing concepts
                </p>
                <p>
                  <strong>Year 2 - Year 4</strong><br/>
                  Computing: solve problems and develop solutions with computing and information technologies in different application areas under different applicaiton contexts
                </p>
              </div>
            </li>

            <li className='timeline-event'>
              <label className='timeline-event-icon'></label>
              <div className='timeline-event-copy'>
                <p className='timeline-event-thumbnail'>06/2019 - 08/2019</p>
                <h3>HKSAR Inland Revenue Department</h3>
                <h4>IT Intern</h4>
                <strong>Duties</strong>
                <ol>
                  <li>Collect user requirements and conduct system design and development of end-user computing applications</li>
                  <li>Review existing EUC (End-User Computing) applications for debugging</li>
                  <li>Document the developed EUC programs including system specifications and user manual</li>
                  <li>Perform user acceptance testing on newly developed EUC applications and oversee programs implementation</li>
                  <li>Other duties as instructed by senior officers</li>
                </ol>
                <strong>Contents</strong><br/>
                <ul>
                  <li>End User Computing Software Testing, Development and Integration</li>
                  <li>Project Cycle include: Collect User Requirements, Design, Development, UAT and Production</li>
                  <li>Programming Language/Tools used: VB6, VB .Net, MS Access SQL, Excel VBA</li>
                </ul>
              </div>
            </li>

            <li className='timeline-event'>
              <label className='timeline-event-icon'></label>
              <div className='timeline-event-copy'>
                <p className='timeline-event-thumbnail'>06/2020</p>
                <h3>The Hong Kong Polytechnic University</h3>
                <h4>Graduation</h4>
                <p>
                  <strong>Bachelor of Computing</strong><br/>
                  Graduated from Hong Kong Polytechnic University
                </p>
              </div>
            </li>

            <li className='timeline-event'>
              <label className='timeline-event-icon'></label>
              <div className='timeline-event-copy'>
                <p className='timeline-event-thumbnail'>07/2020 - 07/2021</p>
                <h3>Oracle</h3>
                <h4>Associate Technical Consultant</h4>
                <strong>Duties</strong>
                <ol>
                  <li>Deliver and implement Oracle ERP & HCM projects</li>
                  <li>Provide technical consulting services to customers</li>
                  <li>Designing, documenting, configuring and testing applications in the project cycle</li>
                  <li>Work with business customers and other team members to translate functional requirements to technical designs</li>
                </ol>
                <strong>Contents</strong><br/>
                <ul>
                  <li>ERP Module Configuration, Data Conversion, OTBI/BIP Report, SaaS, PaaS etc.</li>
                  <li>Project Cycle include: Design, Development, UAT and Production</li>
                  <li>Programming Language/Tools used: HTML, XML, Oracle PL/SQL, Postman, SoapUI</li>
                </ul>
                <strong>Reference Letter</strong><br/>
                <img 
                  src={oracleRef} 
                  style={{width: '95%', display: 'block', cursor: 'pointer', marginLeft: 'auto', marginRight: 'auto'}} 
                  alt='Oracle Reference Letter'
                  onClick={() => handleClickOpen()} 
                />
                <ImageViewerDialog open={open} image={imageOracleRefLetter} onClose={handleClose} />
              </div>
            </li>

            <li className='timeline-event'>
              <label className='timeline-event-icon'></label>
              <div className='timeline-event-copy'>
                <p className='timeline-event-thumbnail'>07/2021 - 06/2024</p>
                <h3>Fujitsu</h3>
                <h4>Application Development Engineer</h4>
                <strong>Duties</strong>
                <ol>
                  <li>Work closely with the Application Team to ensure customers' requirements and schedule are met</li>
                  <li>Assist in application development, testing and troubleshooting</li>
                  <li>Deploy application and prepare system documentation such as program specifications and test documents</li>
                  <li>Maintain up-to-date knowledge of industry trends and advancements</li>
                  <li>Perform ad hoc support to the Project Teams</li>
                </ol>
                <strong>Contents</strong><br/>
                <ul>
                  <li>Mobile applicaiton development, Web applicaiton development, Windows C# .Net applicaiton development, VM maintenance</li>
                  <li>Project Cycle include: Design, Development, UAT and Production</li>
                  <li>Programming Language/Frameworks/Tools used: Java, Swift, HTML, JavaScript (ES5/ES6), React-Native, mobx, Bootstrap, Postman</li>
                </ul>
              </div>
            </li>

            <li className='timeline-event'>
              <label className='timeline-event-icon'></label>
              <div className='timeline-event-copy'>
                <p className='timeline-event-thumbnail'>09/2023 - NOW</p>
                <h3>The Hong Kong Polytechnic University</h3>
                <h4>Master of Science in Information Technology</h4>
                <p>
                  <strong>Stream in Visual Computing</strong><br/>
                  <ul>
                    <li>Artificial Intelligence Concepts</li>
                    <li>Multimedia Computing</li>
                    <li>Computer Vision and Image Processing</li>
                    <li>Machine Learning and Data Analytics</li>
                </ul>
                </p>
              </div>
            </li>

            <li className='timeline-event'>
              <label className='timeline-event-icon'></label>
              <div className='timeline-event-copy'>
                <p className='timeline-event-thumbnail'>06/2024 - NOW</p>
                <h3>Fujitsu</h3>
                <h4>Senior Application Development Engineer</h4>
                <strong>FY24 Staff Promotion</strong>
                <p>
                  <i>"Jason has been instrumental in delivering a critical project for a major banking client. He was tasked with developing a modernized, microservices-based SMS application within a tight timeline, and his exceptional technical expertise and problem-solving abilities were crucial in overcoming challenges and ensuring the successful and satisfactory completion of the project."</i>
                </p>
              </div>
            </li>
          </ul>
        </div>   
      </div>

      <div id='step-container'>
        <QuoteBlockTimelinePage />
      </div>
    </div>
	)
}

const WorkTimelinePage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <div className='content-container'>
          <WorkTimelinePageBody />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default WorkTimelinePage;