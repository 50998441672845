import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';

import './../App.css';
import './IdeaPage.css';
import './ListOfPage.css';

import Navbar from '../components/Navbar';
import CusScrollToTop from '../components/CusScrollToTop';
import HtmlFooter from '../components/HtmlFooter';
import BottomScrollProgressBarUI from '../components/BottomScrollProgressBar';
import { QuoteBlockListOfPianoVideoPage } from '../components/QuoteBlock';
import SliderImage from '../components/SliderImage';
import CusReactPlayer from '../components/CusReactPlayer';
import { pianoVideoLink } from '../constants/links';
import img05 from '../assets/images/slide/img05.png';

const ListOfPianoBody = () => { 
  useEffect(() => window.scrollTo(0,0), []);

	return (
    <div>
      <div id='step-container'>
        <h1>My Piano Video</h1>
        <hr />

        <div className='pianoWrapper'>
          <div className='pianoInline'>
            <p>Beethoven - Für Elise 致愛麗絲</p>
            <CusReactPlayer videolink={pianoVideoLink.furElise}/>
          </div>
          <div className='pianoInline'>
          </div>
        </div>
      </div>

      <div id='step-container' style={{ marginTop: '20px' }}>
        <QuoteBlockListOfPianoVideoPage />
      </div>
    </div>
	)
}

const ListOfPianoPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='When you play, never mind who listens to you. Robert Schumann'
          imgLink={img05}
        />

        <div className='content-container'>
          <ListOfPianoBody />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default ListOfPianoPage;