import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './../../App.css';
import './../ListOfPage.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import SliderImage from '../../components/SliderImage';
import { mainPage } from '../../constants/pageName';
import MainBtn from '../../components/MainBtn';

import img04 from './../../assets/images/slide/img04.png';

const OnePhilosophyProblem = () => { 
  useEffect(() => window.scrollTo(0,0), []);

  const navigate = useNavigate();
  const navigateToArticlesList = () => { navigate(mainPage.myArticle); };

	return (
    <div>
      <div id="step-container">
        <div id="articles-info">
          <h1>《一個現實的哲學問題》</h1>
          <h3>2019年5月23日</h3>
        </div>

        <div id="step-container-body">
          <p className="first">
            在二零一二年的某個星期日，本來打算和朋友去打籃球，但當我想起星期一的化學科測驗和那堆積如山的功課後，我唯有待在家中了。整個星期日的美好時光就用在溫習上。
          </p>
          <p className="first">
            如是者過了三個小時，我還在背那些化學方程式，那真是一件痛苦的事。我頓時感到頭昏腦脹，實在支持不了，需要呼吸一些新鮮空氣。於是我吃了點止痛藥，然後獨自一人到樓下附近走走，臨出門的時候還不忘帶上那份化學筆記。
          </p>
          <p className="first">
            我沿著濕地公園路一直走，大概是六時多，路上沒有太多人，倒挺安靜的。於是我拿出那份筆記，溫習那和岩石有關的物質時，又是一陣頭痛。過了一會，止痛藥似乎發揮它的效用，感覺好了不少。我望望天空，暮色暗淡，殘陽如血，那如鑲金邊的落日，此時正圓，光芒四射，如夢似幻，好不真實。已經很久沒有見過這樣的景色了。在一個精神恍惚的狀態下，實難以集中溫習，我乾脆放下筆記，一邊散步，一邊胡思亂想。
          </p>
          <p className="first">
            我在想，為什麼要這麼辛苦地迫自己溫習那份筆記呢？為了獲得一個好成績。那為什麼要在乎成績呢？為了將來能讀上大學，有份好工作。為什麼要有份好工作呢？為了賺更多的錢。至於為什麼要賺更多的錢就不用我說了，畢竟我想追求更好的生活，而這就是我辛苦迫自己溫習那份筆記的原因。
          </p>
          <p className="first">
            我們每個人都有一個終極目標，而在此之前，我們所完成的每件事情，都是為了達到終極目標的先決條件。然而，這個所謂的終極目標，只不過是環境因素施加而形成的，例如一個窮人希望能富有、一個將死之人希望有更多時間。故此，我們只不過是自己追求各種目標的工具，而非操控者。
          </p>
          <p className="first">
            我並不喜歡這個結論，因為若然這成立的話，生命的意義和價值都不再存在，因為眼前的一切都不過是假象 — 我們以為自己是人生的作者，但實際上，我們所做的只不過是外在因素影響；我們就跟動物一樣，為適應不同環境而生存。但這是真的嗎？
          </p>
          <p className="first">
            「鈴鈴……」一架單車在我身邊駛過，我回過神來，望望手錶，已是七時多了。我慢慢地走回家，心裡有一種難以形容的抑鬱。
          </p>
          <p className="first">
            多年之後，在一次思緒沉澱時，得以重新思考這個問題。我們做某件事情可以是根據自己的法則而行動的，而這個法則甚至可以超越外在環境因素的影響，例如一個窮人不會為了致富而做出傷天害理之事。這是我們可以選擇的，但若然我們選擇違背原則，那麼我們之後所做的每件事都不會是自己能夠選擇的，因為我們將這個權力放棄了。
          </p>
          <p className="first">
            雖然這沒法改變一個窮人想過上好生活的目標，畢竟人相對整個宇宙而言，實在是太過渺小，但這並不代表我們沒有選擇。人和動物不同的地方在於我們懂得擇善固執，我們不會輕易向現實低頭，但我們亦不會逃避現實，反之，我們一邊接受現實，一邊和現實「討價還價」；我們一邊適應，一邊改變。
          </p>
        </div>

        <br/><br/>

        <div className = 'buttonWrapper'>
          <MainBtn text='Back' onClick={() => navigateToArticlesList()}/>
        </div>
      </div>
    </div>
	)
}

const OnePhilosophyProblemPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='我們一邊接受現實，一邊和現實「討價還價」'
          imgLink={img04}
        />

        <div className='content-container'>
          <OnePhilosophyProblem />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default OnePhilosophyProblemPage;