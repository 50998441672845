import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import './../App.css';
import './SectionAboutMe.css';
import MainBtn from '../components/MainBtn';
import { mainPage } from '../constants/pageName';
import userIcon from '../assets/images/icon/userIcon.png';
import ImageViewerDialog from '../components/ImageViewerDialog';

import googleUIUXCert from '../assets/images/myCerts/googleUIUXCert.png';
import peopleCertITILFoundation from '../assets/images/myCerts/peopleCertITILFoundation.png';
import kubernetes from '../assets/images/myCerts/kubernetes.jpeg';
import { sectionAbtMeLinks } from '../constants/links';

interface ImageCert {
  img: string;
  title: string;
  cols?: number;
  rows?: number;
}

const srcset = (image: string, size: number, rows = 1, cols = 1) => {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const SectionAboutMe = () => {
  const navigate = useNavigate();
  const navigateToWork = () => { navigate(mainPage.timeline); };
  const navigateToStudy = () => { navigate(mainPage.studyMaterial); };

  const imageCertsList: ImageCert[] = [
    {
      img: googleUIUXCert,
      title: 'Google UIUX Cert',
      rows: 2,
      cols: 1,
    },
    {
      img: peopleCertITILFoundation,
      title: 'ITIL Foundation',
      rows: 2,
      cols: 1,
    },
    {
      img: kubernetes,
      title: 'Kubernetes: Microservices',
      rows: 2,
      cols: 1,
    },
  ];

  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<ImageCert | null>(null);

  const handleClickOpen = (image: ImageCert) => {
    setCurrentImage(image);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

	return (
    <>
      <div className='pageSection' id='sectionAboutMe'>
        <div id='sectionAboutMe-row'>
          <div id='sectionAboutMeLeft'>
            <h2 id='hAboutMe'>About Me</h2>
            <p id='pAboutMe'>
              Hello, wellcome to my webpage.
              I believe that programming skills is a tool,
              while problem solving is the plan,
              and creativity is the spirit.
              Great things happen when combine:
              <br/><br/>
              <li >40% Coding</li>
              <li >30% Design</li>
              <li >30% Creation</li>
              <br/>
              I have solid experience on Software Development, Web Development and Mobile Development,
              currently work in <a href={sectionAbtMeLinks.fujitsuHK} target='_blank' rel='noreferrer'>Fujitsu</a> as a <b>Senior Application Development Engineer</b>. 
              Part-time MSc INFORMATION TECHNOLOGY
              For more information please visit my <a href={sectionAbtMeLinks.myLinkedin} target='_blank' rel='noreferrer'>LinkedIn profile</a>.
            </p>
          </div>
          
          <div id='sectionAboutMeRight'>
            <img src={userIcon} id='usrIcon' alt='usrIcon'/>
            <p id='name'>Jason Lai</p>
            <hr style={{width: '35%', border: 'none', height: '3px', backgroundColor: 'black'}}/>
            <table id='myInformation'>
              <tbody>
                <tr>
                  <td colSpan={2} style={{textAlign: 'center'}}>Thinker, Creator, Dreamer</td>
                </tr>
                <tr>
                  <td>Nation: </td>
                  <td>Hong Kong, China</td>
                </tr>
                <tr>
                  <td>Year of Birth: </td>
                  <td>1997</td>
                </tr>
                <tr>
                  <td>College: </td>
                  <td><a href={sectionAbtMeLinks.polyuHome} target='_blank' rel='noreferrer'>Hong Kong Polytechnic University</a></td>
                </tr>
                <tr>
                  <td>Department: </td>
                  <td><a href={sectionAbtMeLinks.polyuComp} target='_blank' rel='noreferrer'>Computing (Faculty of Engineering)</a></td>
                </tr>
                <tr>
                  <td>Major: </td>
                  <td>Master of Science in Information Technology</td>
                </tr>
                <tr>
                  <td>Communicate Language: </td>
                  <td>Chinese and English</td>
                </tr>
                <tr>
                  <td>Skills: </td>
                  <td>Software Development, Web Application, Mobile Development, Game Design and Development, General Computing Technique etc.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id='sectionAboutMe-row'>
          <div id='sectionAboutMeRowChild'>
            <h3 id='hCert'><b>My Certificates</b></h3>
            <br />
            <ImageList sx={{ width: 'auto', height: 250 }} variant='quilted' cols={4} rowHeight={121}>
              {imageCertsList.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1} onClick={() => handleClickOpen(item)}>
                  <img
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading='lazy'
                    style={{ cursor: 'pointer' }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
            <ImageViewerDialog open={open} image={currentImage} onClose={handleClose} />
          </div>
        </div>
      </div>

      <div className = 'buttonWrapper'>
        <MainBtn text='Timeline' onClick={() => navigateToWork()}/>
        <MainBtn text='Study Materials' onClick={() => navigateToStudy()}/>
      </div>
    </>
	)
}

export default SectionAboutMe;