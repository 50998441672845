export const sectionAbtMeLinks = {
  fujitsuHK: 'https://global.fujitsu/en-hk',
  myLinkedin: 'https://www.linkedin.com/in/jason-lai-339708152',
  polyuHome: 'https://www.polyu.edu.hk/web/en/home/index.html',
  polyuComp: 'https://www.polyu.edu.hk/comp/',
}

export const pianoVideoLink = {
  furElise: 'https://www.youtube.com/watch?v=VDjfuKTbiK8',
}

export const gameVideoLink = {
  aiGamePlay: 'https://youtu.be/pYDqTKCU8Yc',
}