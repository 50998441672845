import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './../../App.css';
import './../ListOfPage.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import SliderImage from '../../components/SliderImage';
import { mainPage } from '../../constants/pageName';
import MainBtn from '../../components/MainBtn';

import img04 from './../../assets/images/slide/img04.png';

const Fear = () => { 
  useEffect(() => window.scrollTo(0,0), []);

  const navigate = useNavigate();
  const navigateToArticlesList = () => { navigate(mainPage.myArticle); };

	return (
    <div>
      <div id="step-container">
        <div id="articles-info">
          <h1>《恐懼》</h1>
          <h3>2014年10月6日</h3>
        </div>

        <div id="step-container-body">
          <p className="first">
            恐懼是一種當人面臨威脅時的心理反應，有些人對水有恐懼、有些人對蛇有恐懼、有些人甚至對驗血有恐懼。然而，這些都只是一時三刻的恐懼，真正可怕的是無盡的惡夢……
          </p>
          <p className="first">
            史蒂芬金曾寫道：「惡夢並不受邏輯的控制，而且如果惡夢能夠解釋，反會失去原有的趣味，因為惡夢和恐懼的藝術是相互對立的。」
          </p>
          <p className="first">
            一般人很難從現實中體會到真正的恐懼，只有從惡夢裡面，自己變成一個被害者，才會體驗到深入內心的恐懼。
          </p>
          <p className="first">
            正如史蒂芬金所寫道：「惡夢並不受邏輯的控制」，在夢裡面，我們要面對一個扭曲的世界，裡面的一切似乎都不合理；而且夢中的自己，似乎永遠都是處於一個不利的位置。你明明出盡全力地奔跑，卻跑不快；你手中或許有把手鎗，卻永遠射不出一發子彈。終於，你走到了一個盡頭，最可怕的夢魘之火漸漸靠近，無助、絕望、頻臨死亡邊緣，使恐懼蔓延到全身每個部分，雙腳開始有點抽搐，一踏空，仿佛要掉入一個無底的深淵。就在這時，從睡夢中醒來，以為擺脫了夢魘的折磨，但其實真正的折磨，才剛開始……即使感到十分睏，你也不敢合上雙眼，因為每當合上眼，剛才的一幕又會再次重現。你開始愈來愈疲倦，從而出現精神不足所引起的幻覺，好像有東西想從房門或窗外走進來，於是你死命盯著門口。可是，你無法跟疲倦對抗，再一次地，黑暗吞噬了整個世界，你又回到那個扭曲的世界，成為一個被害者。
          </p>
          <p className="first">
            在漆黑一片的森林裡，你會跟著燈光奔跑；在現實中，要到光明，就必須度過漫漫長夜。夢魘可以令一個人害怕每一個晚上，這種長久、不停的恐懼令人生不如死。「被害者總會不斷詢問『為什麼？』但卻得不到任何答案。其實也不應該有答案，畢竟能夠烙印在我們腦海中，永遠揮之不去的就是無解的謎團。」
          </p>
        </div>

        <br/><br/>

        <div className = 'buttonWrapper'>
          <MainBtn text='Back' onClick={() => navigateToArticlesList()}/>
        </div>
      </div>
    </div>
	)
}

const FearPage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <SliderImage 
          captionText='畢竟能夠烙印在我們腦海中，永遠揮之不去的就是無解的謎團'
          imgLink={img04}
        />

        <div className='content-container'>
          <Fear />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default FearPage;