import ScrollToTop from 'react-scroll-to-top';

const CusScrollToTop = () => {
  return(
    <ScrollToTop 
      smooth 
      color='white' 
      style={{ 
        margin: '10px', 
        width: '60px', 
        height: '60px', 
        alignContent: 'center', 
        alignItems: 'center', 
        justifyContent: 'center', 
        justifyItems: 'center', 
        backgroundColor: '#525252',
        opacity: 0.8
      }}
    />
  )
}

export default CusScrollToTop;