import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React, { useEffect } from 'react';

import './../../App.css';
import './../IdeaPage.css';
import './studyPage.css';
import './../../components/CodeBlock.css';

import Navbar from '../../components/Navbar';
import CusScrollToTop from '../../components/CusScrollToTop';
import HtmlFooter from '../../components/HtmlFooter';
import BottomScrollProgressBarUI from '../../components/BottomScrollProgressBar';
import SectionLine from '../../components/SectionLine';
import CodeBlock, { codeBlockmode, codeBlocktheme } from '../../components/CodeBlock';
import { 
  usersJSON,
  bookmarkJSON,
  pyCodeStruct,
  q1Sol,
  jsonOutput,
  q2Sol,
  q3Sol,
  q4Sol,
  q5Sol,
  q6Sol,
  q7Sol,
  q8Sol,
  q9sol,
} from '../../constants/reactAceCodeText';

import cmdImg from '../../assets/images/study/cmd_expy.png';
import postmanImg from '../../assets/images/study/postman_result_1.png';

const BookmarkingService = () => {
  useEffect(() => window.scrollTo(0,0), []);

	return (
    <div className='step-container'>
      <h1>Bookmarking Service Using RESTful API</h1>
      <hr/>
      <div className='step-container-body'>
        <div id='study-contents'>
          This article shows how to implement a RESTful API for a bookmarking service using python flask framework.
          The following packages will be used:
          <ul><li>request, flask, json, sqlite3</li></ul>
       
          <h2>Pre-Requirements</h2>
          <ul>
            <li>Python</li>
            <li>Postman</li>
          </ul>

          <h2>Objective</h2>
          Implement a RESTful API for a bookmarking service using python flask framework. 

          <h2>Basic Setup</h2>
          <ol>
            <li>Install <a target='_blank' rel='noopener noreferrer' href='https://www.python.org/'>Python</a>.</li>
            <li>
              Install python package 'flask'.<br/>
              For MAC OS:<br/>
              <div id='cmdline'>pip install flask</div>

              For Windows 10:<br/>
              First navigate to your python install directory, and go to the 'Scripts' folder, For example:
              <div id='cmdline'>cd C:\Users\User\AppData\Local\Programs\Python\Python38-32\Scripts</div>
              Then install the flask:
              <div id='cmdline'>pip install flask</div>
            </li>
            <li>Install <a target='_blank' rel='noopener noreferrer' href='https://www.postman.com/downloads/'>Postman</a>.</li>
            <li>
              Download the dB testing file here:
              <a target='_blank' rel='noopener noreferrer' href='https://drive.google.com/file/d/1yigHtxsQmIOUU6_zRxTU6trH5DDR27e3/view?usp=sharing' download>bookmarks.db</a>.
              <br/>
              DB structure:
              <br/>
              Users:
              <div className='codeblock-container'>
                <CodeBlock 
                  className='codeblock'
                  mode={codeBlockmode.json}
                  theme={codeBlocktheme.monokai}
                  id={'usersJSON'}
                  value={usersJSON}
                  style={{ width: '100%', height: '260px' }}
                />
              </div>
              Bookmark:
              <div className='codeblock-container'>
                <CodeBlock 
                  className='codeblock'
                  mode={codeBlockmode.json}
                  theme={codeBlocktheme.monokai}
                  id={'bookmarkJSON'}
                  value={bookmarkJSON}
                  style={{ width: '100%', height: '330px' }}
                />
              </div>
            </li>
            <li>
              Python code structure:
              <div className='codeblock-container'>
                <CodeBlock 
                  className='codeblock'
                  mode={codeBlockmode.python}
                  theme={codeBlocktheme.monokai}
                  id={'pyCodeStruct'}
                  value={pyCodeStruct}
                  style={{ width: '100%', height: '420px' }}
                />
              </div>
            </li>
          </ol>

          <h2>Questions & Solution</h2>
          <h3>Q1.</h3>
          Getting all users.
          <div style={{ overflow: 'auto' }}>
            <table className='contents-questions'>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>http://localhost/bookmarking/users</td>
                </tr>
                <tr>
                  <td>HTTP Request Method</td>
                  <td>GET</td>
                </tr>
                <tr>
                  <td>HTTP Response Body</td>
                  <td>All users (ID, name) in JSON (Sorted by ascending user ID(s))</td>
                </tr>
                <tr>
                  <td>HTTP Response Code</td>
                  <td>200 OK</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Q1 Solution</h3>
          bookmarking_service.py
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.python}
              theme={codeBlocktheme.monokai}
              id={'q1Sol'}
              value={q1Sol}
              style={{ width: '100%', height: '420px' }}
            />
          </div>

          <h3>Test Q1 Solution</h3>
          <ol>
            <li>Put 'bookmarking_service.py' and 'bookmarks.db' into the same directory.</li>
            <li>
              Open cmd, enter:
              <div id='cmdline'>python bookmarking_service.py</div>
              You may need to add Python to Windows PATH before execute it using the cmd.
              For more detail search 'add Python to Windows PATH' with your browser.
              You will get the output like this:
              <img src={cmdImg} className='imageBox' alt=''/>
            </li>
            <li>
              Open Postman, in Postman chose the GET method and enter the URL: 
              <div id='cmdline'>http://127.0.0.1:5000/bookmarking/users</div>
              <img src={postmanImg} className='imageBox' alt=''/>
              JSON output:
              <div className='codeblock-container'>
                <CodeBlock 
                  className='codeblock'
                  mode={codeBlockmode.json}
                  theme={codeBlocktheme.monokai}
                  id={'jsonOutput'}
                  value={jsonOutput}
                  style={{ width: '100%', height: '260px' }}
                />
              </div>
            </li>
          </ol>

          <SectionLine />

          <h3>Q2.</h3>
          Adding one or more new user(s).
          <div style={{ overflow: 'auto' }}>
            <table className='contents-questions'>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>http://localhost/bookmarking</td>
                </tr>
                <tr>
                  <td>HTTP Request Method</td>
                  <td>POST</td>
                </tr>
                <tr>
                  <td>HTTP Response Body</td>
                  <td>New user's id and name in JSON format</td>
                </tr>
                <tr>
                  <td>HTTP Response Code</td>
                  <td>
                    <ul>
                      <li>200 (Creaded): Request is Successful</li>
                      <li>400 (Bad Request): One/more of the user(s) exists already</li>
                      <li>500 (Internal Server Error): When the input is malformed or request that may raise exceptions</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Q2 Solution</h3>
          bookmarking_service.py
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.python}
              theme={codeBlocktheme.monokai}
              id={'q2Sol'}
              value={q2Sol}
              style={{ width: '100%', height: '570px' }}
            />
          </div>

          <SectionLine />

          <h3>Q3.</h3>
          Deleting a user, also delete the bookmark.
          <div style={{ overflow: 'auto' }}>
            <table className='contents-questions'>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>http://localhost/bookmarking/[user id]</td>
                </tr>
                <tr>
                  <td>HTTP Request Method</td>
                  <td>DELETE</td>
                </tr>
                <tr>
                  <td>HTTP Response Code</td>
                  <td>
                    <ul>
                      <li>204 No Content (User is successfully deleted)</li>
                      <li>404 Not Found (User is not found)</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Q3 Solution</h3>
          bookmarking_service.py
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.python}
              theme={codeBlocktheme.monokai}
              id={'q3Sol'}
              value={q3Sol}
              style={{ width: '100%', height: '360px' }}
            />
          </div>

          <SectionLine />

          <h3>Q4.</h3>
          Getting all bookmarks.
          <div style={{ overflow: 'auto' }}>
            <table className='contents-questions'>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>http://localhost/bookmarking/bookmarks</td>
                </tr>
                <tr>
                  <td>HTTP Request Method</td>
                  <td>GET</td>
                </tr>
                <tr>
                  <td>Query String (Optional)</td>
                  <td>
                    tags=[tag name]<br/>
                    Return only those bookmarks with the specified tags
                    <br/><br/>
                    count=[number of bookmarks to be returned]
                    <br/><br/>
                    offset=[the position of the first bookmark to be returned (counting from 1)]
                  </td>
                </tr>
                <tr>
                  <td>HTTP Response Body</td>
                  <td>
                    All bookmarks in JSON for all users (Sorted in ascending order of the bookmarks' URL(followed by ascending order of user_id))
                  </td>
                </tr>
                <tr>
                  <td>HTTP Response Code</td>
                  <td>200 OK</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Q4 Solution</h3>
          bookmarking_service.py
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.python}
              theme={codeBlocktheme.monokai}
              id={'q4Sol'}
              value={q4Sol}
              style={{ width: '100%', height: '1450px' }}
            />
          </div>

          <SectionLine />

          <h3>Q5.</h3>
          Getting all bookmarks for a certain user.
          <div style={{ overflow: 'auto' }}>
            <table className='contents-questions'>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>http://localhost/bookmarking/bookmarks/[user id]</td>
                </tr>
                <tr>
                  <td>HTTP Request Method</td>
                  <td>GET</td>
                </tr>
                <tr>
                  <td>Query String (Optional)</td>
                  <td>
                    tags=[tag name]<br/>
                    Filter for only those bookmarks with the specified tags<br/>
                    (tags are separated by comma of more than 1 tags)
                    <br/><br/>
                    count=[number of bookmarks to be returned]<br/>
                    Limit the number of bookmarks to be returned
                    <br/><br/>
                    offset=[the position of the first bookmark to be returned (counting from 1)]<br/>
                    Specify the first bookmark to be returned
                  </td>
                </tr>
                <tr>
                  <td>HTTP Response Body</td>
                  <td>
                    All bookmarks in JSON for all users (Sorted in ascending order of the bookmarks' URL(followed by ascending order of user_id))
                  </td>
                </tr>
                <tr>
                  <td>HTTP Response Code</td>
                  <td>
                    <ul>
                      <li>200 OK</li>
                      <li>404 Not Found: The user does not exist</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Q5 Solution</h3>
          bookmarking_service.py
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.python}
              theme={codeBlocktheme.monokai}
              id={'q5Sol'}
              value={q5Sol}
              style={{ width: '100%', height: '1470px' }}
            />
          </div>

          <SectionLine />

          <h3>Q6.</h3>
          Getting a target bookmarks for a certain user.
          <div style={{ overflow: 'auto' }}>
            <table className='contents-questions'>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>http://localhost/bookmarking/bookmarks/[user id]/[encoded bookmark's URL]</td>
                </tr>
                <tr>
                  <td>HTTP Request Method</td>
                  <td>GET</td>
                </tr>
                <tr>
                  <td>HTTP Response Body</td>
                  <td>
                    The target bookmark in JSON format
                  </td>
                </tr>
                <tr>
                  <td>HTTP Response Code</td>
                  <td>200 OK</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Q6 Solution</h3>
          bookmarking_service.py
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.python}
              theme={codeBlocktheme.monokai}
              id={'q6Sol'}
              value={q6Sol}
              style={{ width: '100%', height: '420px' }}
            />
          </div>

          <SectionLine />

          <h3>Q7.</h3>
          Adding one or more bookmark(s) for a user.
          <div style={{ overflow: 'auto' }}>
            <table className='contents-questions'>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>http://localhost/bookmarking/bookmarks/[user id]/bookmarks</td>
                </tr>
                <tr>
                  <td>HTTP Request Method</td>
                  <td>POST</td>
                </tr>
                <tr>
                  <td>HTTP Request Body</td>
                  <td>
                    New bookmark to be added (in JSON format)
                  </td>
                </tr>
                <tr>
                  <td>HTTP Response Body</td>
                  <td>
                    The new bookmark in JSON format (if operation is successful)
                    <br/><br/>
                    The reason for the error (if operation is not successful)
                  </td>
                </tr>
                <tr>
                  <td>HTTP Response Code</td>
                  <td>
                    <ul>
                      <li>201 Created: Request is successful</li>
                      <li>400 Bad Request: Bookmark (with a certain URL) already exists</li>
                      <li>404 Not Found: The user does not exist</li>
                      <li>500 Internal Server Error: when the input is malformed or request that may raise exceptions</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Q7 Solution</h3>
          bookmarking_service.py
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.python}
              theme={codeBlocktheme.monokai}
              id={'q7Sol'}
              value={q7Sol}
              style={{ width: '100%', height: '710px' }}
            />
          </div>

          <SectionLine />

          <h3>Q8.</h3>
          Updating the title/tag(s) for one or more bookmark(s) for a target user.
          <div style={{ overflow: 'auto' }}>
            <table className='contents-questions'>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>http://localhost/bookmarking/[user id]/bookmarks/[encoded bookmark's URL]</td>
                </tr>
                <tr>
                  <td>HTTP Request Method</td>
                  <td>PUT</td>
                </tr>
                <tr>
                  <td>HTTP Response Body</td>
                  <td>
                    The updated bookmark(s) in JSON format (if operation is successful)
                    <br/><br/>
                    The reason for the error (if operation is not successful)
                  </td>
                </tr>
                <tr>
                  <td>HTTP Response Code</td>
                  <td>
                    <ul>
                      <li>201 (Created): Request is successful</li>
                      <li>404 Not Found: The user does not exist/the bookmark does not exist</li>
                      <li>500 (Internal Server Error): When the input is malformed or request that may raise exceptions</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Q8 Solution</h3>
          bookmarking_service.py
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.python}
              theme={codeBlocktheme.monokai}
              id={'q8Sol'}
              value={q8Sol}
              style={{ width: '100%', height: '710px' }}
            />
          </div>

          <SectionLine />

          <h3>Q9.</h3>
          Deleting a bookmark for a target user.
          <div style={{ overflow: 'auto' }}>
            <table className='contents-questions'>
              <tbody>
                <tr>
                  <td>URL</td>
                  <td>http://localhost/bookmarking/[user id]/bookmarks/[encoded bookmark's URL]</td>
                </tr>
                <tr>
                  <td>HTTP Request Method</td>
                  <td>DELETE</td>
                </tr>
                <tr>
                  <td>HTTP Response Body</td>
                  <td>
                    The new bookmark in JSON format (if operation is successful)
                    <br/><br/>
                    The reason for the error (if operation is not successful)
                  </td>
                </tr>
                <tr>
                  <td>HTTP Response Code</td>
                  <td>
                    <ul>
                      <li>204 (No Content): Request is successful</li>
                      <li>404 Not Found: The user does not exist/the target bookmark does not exist</li>
                      <li>500 (Internal Server Error): Response when the input is malformed or request that may raise exceptions</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>Q9 Solution</h3>
          bookmarking_service.py
          <div className='codeblock-container'>
            <CodeBlock 
              className='codeblock'
              mode={codeBlockmode.python}
              theme={codeBlocktheme.monokai}
              id={'q9sol'}
              value={q9sol}
              style={{ width: '100%', height: '400px' }}
            />
          </div>

          <SectionLine />

          <h3>Download Full Solution</h3>
          <a target='_blank' rel='noopener noreferrer' href='https://github.com/JasonLai97/BookmarkingService.git'>bookmarking_service.py</a>
        </div>
    </div>
    </div>
	)
}

const BookmarkingServicePage = () => {
  return (
    <div className='baseDiv'>
      <div className='bodyDiv'>
        {/* Sticky Navbar */}
        <Navbar 
          inAboutMeArea={false}
          inSkillArea={false}
          inHobbyArea={false}
          inScheduleArea={false}
          inContactArea={false}
          inOtherPage={true}
        />

        {/* Auto scroll (smooth) to the top */}
        <CusScrollToTop />

        <div className='content-container'>
          <BookmarkingService />
        </div>

        <HtmlFooter inOtherPage={true} />
        <BottomScrollProgressBarUI />
      </div>
    </div>
  )
}

export default BookmarkingServicePage;