import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress } from '@mui/material';

interface ImageViewerDialogProps {
  open: boolean;
  image: { img: string; title: string } | null;
  onClose: () => void;
}

const ImageViewerDialog: React.FC<ImageViewerDialogProps> = ({ open, image, onClose }) => {
  const [isLoading, setIsLoading] = useState(true);

  // Reset isLoading whenever the image changes
  useEffect(() => {
    if (image) {
      setIsLoading(true);
    }
  }, [image]);

  // Handle image load
  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby='image-dialog-title'
      maxWidth='md'
    >
      <DialogTitle id='image-dialog-title'>
        {image?.title}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {image ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden', minHeight: '350px', minWidth: '350px' }}>
          {isLoading && (
            <CircularProgress /> // Show progress while the image is loading
          )}
          <img
            src={image.img}
            alt={image.title}
            style={{ maxHeight: '80vh', maxWidth: '100%', display: isLoading ? 'none' : 'block' }}
            onLoad={handleImageLoad}
          />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '350px', minWidth: '350px' }}>
          <CircularProgress />
        </Box>
      )}
    </Dialog>
  );
};

export default ImageViewerDialog;