import AceEditor from 'react-ace-builds';
import 'react-ace-builds/webpack-resolver-min';

import './../App.css';
import './../components/CodeBlock.css';

export const codeBlockmode = {
  javascript: 'javascript',
  java: 'java',
  python: 'python',
  xml: 'xml',
  ruby: 'ruby',
  sass: 'sass',
  markdown: 'markdown',
  mysql: 'mysql',
  json: 'json',
  html: 'html',
  handlebars: 'handlebars',
  golang: 'golang',
  csharp: 'csharp',
  coffee: 'coffee',
  css: 'css',
}

export const codeBlocktheme = {
  monokai: 'monokai',
  github: 'github',
  tomorrow: 'tomorrow',
  kuroir: 'kuroir',
  twilight: 'twilight',
  xcode: 'xcode',
  textmate: 'textmate',
  solarized_dark: 'solarized_dark',
  solarized_light: 'solarized_light',
  terminal: 'terminal',
}

type codeBlockProps = {
  mode: string,
  theme: string,
  id: string,
  value: string,
  className?: string,
  style?: React.CSSProperties
}

const CodeBlock = (props: codeBlockProps) => {
  const onChange = (newValue: string) => {
    console.log('CodeBlock Editing', newValue);
  };

  return (
    <AceEditor
      className={props.className}
      mode={props.mode}
      theme={props.theme}
      onChange={onChange}
      name={props.id}
      value={props.value}
      style={props.style}
    />
  )
}

export default CodeBlock;